import Layout from "../components/Layout.js";
import HomeHeader from "../components/Home/HomeHeader/HomeHeader.js";
import HowItWorks from "../components/Home/HowItWorks/HowItWorks.js";
import OurSolutions from "../components/Home/OurSolutions/OurSolutions.js";
import WhyRimble from "../components/Home/WhyRimble/WhyRimble.js";
import SupportedGames from "../components/Home/SupportGames/SupportGames.js";
import ContactUs from "../components/Home/ContactUs/ContactUs.js";
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

function LandingPage() {
    return (
        <Layout>
            <Helmet>
                <title>Rimble - AI-Powered Esports Odds & Predictions | Next Generation Engagement</title>
                <meta name="description" content="Rimble is the leading AI-powered platform for esports odds, predictions, and fan engagement. Explore our solutions for real-time data, analytics, and innovative betting experiences for League of Legends, Dota 2, CS:GO, and more." />
                <meta name="keywords" content="esports, odds, predictions, betting, AI, analytics, fan engagement, League of Legends, Dota 2, CS:GO, Valorant" />
            </Helmet>
            <main>
                <HomeHeader />
                <HowItWorks />
                <OurSolutions />
                <WhyRimble />
                <SupportedGames />
                <ContactUs />
            </main>
        </Layout>
    );

}

export default LandingPage;
