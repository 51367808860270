import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../WhyRimble/css/why-rimble.css";
import advanced_micro from "../../../assets/images/why_rimble/advanced_micro_analytics.png";
import unique_data from "../../../assets/images/why_rimble/unique_data_generation.png";
import real_time from "../../../assets/images/why_rimble/real_time_odds_support.png";
import { Helmet } from 'react-helmet';

function WhyRimble() {
    return (
        <div className="why-rimble-section" id="why-rimble-section">
            <Helmet>
                <meta name="description" content="Discover why Rimble is the industry-leading esports data platform. We offer superior support for micro-markets, unique data access through official and streamer feeds, and seamless integration for your business." />
            </Helmet>
            <Container className="why-rimble-container">
                <Row className="why-rimble-header-container">
                    <Col xs={12}>
                        <h2 className="why-rimble-section-header">WHY RIMBLE</h2>
                        <p className="why-rimble-section-subheader">Industry Leading Support</p>
                    </Col>
                </Row>
                <Row className="why-rimble-reasons-row">
                    <Col md={4} className="why-rimble-reason-col">
                        <img className="why-rimble-reason-image" src={advanced_micro} loading="lazy" decoding="async" alt="Advanced Micro-Markets and SGP Support" />
                        <h3 className="why-rimble-reason-heading">SGP + Micro markets</h3>
                        <p className="why-rimble-reason-description">Rimble offers a deeper level of fan engagement by focusing on markets fans care about.</p>
                    </Col>
                    <Col md={4} className="why-rimble-reason-col">
                        <img className="why-rimble-reason-image" src={unique_data} loading="lazy" decoding="async" alt="Unique Esports Data Access and Generation" />
                        <h3 className="why-rimble-reason-heading">Unique Data Access</h3>
                        <p className="why-rimble-reason-description">Powered by official data wherever possible, Rimble also has exclusive streamer feeds to enhance your existing operations.</p>
                    </Col>
                    <Col md={4} className="why-rimble-reason-col">
                        <img className="why-rimble-reason-image" src={real_time} loading="lazy" decoding="async" alt="Easy and Seamless Integration" />
                        <h3 className="why-rimble-reason-heading">Ease of Integration</h3>
                        <p className="why-rimble-reason-description">Our APIs and white-label experiences are designed to reduce development hassle and ease integration.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WhyRimble;
