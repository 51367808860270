import React, { useState } from "react";
import "../SupportGames/css/supported-games.css";
import { Container, Row, Col } from "react-bootstrap";
import GameCard from "../../GameCard/GameCard.js";
import GameCardData from "../../GameCard/GameCardData.js";
import { Helmet } from 'react-helmet';

// Import images
import lolSelected from "../../../assets/images/supported_games/league_selected.png";
import lolNotSelected from "../../../assets/images/supported_games/league_not_selected.png";
import dota2Selected from "../../../assets/images/supported_games/dota2_selected.png";
import dota2NotSelected from "../../../assets/images/supported_games/dota2_not_selected.png";
import csgoSelected from "../../../assets/images/supported_games/csgo_selected.png";
import csgoNotSelected from "../../../assets/images/supported_games/csgo_not_selected.png";
import codSelected from "../../../assets/images/supported_games/call_of_duty_selected.png";
import codNotSelected from "../../../assets/images/supported_games/call_of_duty_not_selected.png";
import valSelected from "../../../assets/images/supported_games/valorant_selected.png";
import valNotSelected from "../../../assets/images/supported_games/valorant_not_selected.png";
import rocketSelected from "../../../assets/images/supported_games/rocket_league_selected.png";
import rocketNotSelected from "../../../assets/images/supported_games/rocket_league_not_selected.png";

const images = {
    lol: { selected: lolSelected, notSelected: lolNotSelected },
    dota2: { selected: dota2Selected, notSelected: dota2NotSelected },
    csgo: { selected: csgoSelected, notSelected: csgoNotSelected },
    cod: { selected: codSelected, notSelected: codNotSelected },
    val: { selected: valSelected, notSelected: valNotSelected },
    rl: { selected: rocketSelected, notSelected: rocketNotSelected },
};

function SupportedGames() {
    const [active, setActive] = useState("lol");

    const activeHandler = (event) => {
        setActive(event.target.id);
    };

    const gameData = GameCardData.find((game) => game.id === active);

    return (
        <div className="supported-games-section" id="supported-games-section">
            <Helmet>
                <meta name="description" content="Explore the wide range of esports games supported by Rimble's AI-powered data platform. Get in-depth stats and analytics for League of Legends, Dota 2, CS:GO, Valorant, and more." />
                <meta name="keywords" content="supported esports games, esports games list, game coverage, esports data, League of Legends, Dota 2, CS:GO, Valorant, Rocket League, Call of Duty, game statistics, esports analytics" />
            </Helmet>
            <Container className="supported-games-container">
                <Row className="supported-games-header-container">
                    <Col xs={12}>
                        <h2 className="supported-games-section-header">SUPPORTED GAMES</h2>
                        <p className="supported-games-section-subheader">Extensive Coverage</p>
                        <p className="supported-games-section-description">Click on a game to view detailed statistics and analytics.</p>
                    </Col>
                </Row>
                <Row className="supported-games-content-row">
                    <Col className="supported-games-icons-col">
                        {Object.keys(images).map((game) => (
                            <div className="supported-game-icon-container" key={game}>
                                <img
                                    id={game}
                                    src={active === game ? images[game].selected : images[game].notSelected}
                                    className={`supported-game-icon ${active === game ? "active-game-icon" : ""}`}
                                    alt={`Explore ${game.toUpperCase()} Esports Stats`}
                                    onClick={activeHandler}
                                    aria-label={`View stats for ${game.toUpperCase()}`}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') { activeHandler(e); } }}
                                />
                                <p className={`supported-game-info-text ${active === game ? "active-game-info-text" : ""}`}>
                                    {game.toUpperCase()}
                                </p>
                            </div>
                        ))}
                    </Col>
                    <Col className="supported-game-card-col">
                        {gameData && <GameCard data={gameData} />}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SupportedGames;
