import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid, _ } from 'gridjs-react';
import jsSHA from "jssha";
import "../components/Dashboard/css/dashboard.css";
import Layout from "../components/Layout.js";
import moment from "moment-timezone";
import { Container, Nav, Row, Col, Button, Form, Image, Spinner, Dropdown, Navbar } from "react-bootstrap";
import { connect } from 'react-redux';
import { navigate as navigateAction } from '../store/navigationSlice';
import withNavigation from './withNavigation';

import leagueDashImg from "../assets/images/dashboard/league_dash.png";
import valDashImg from "../assets/images/dashboard/val_dash.png";
import dota2DashImg from "../assets/images/dashboard/dota2_dash.png";
import csgoDashImg from "../assets/images/dashboard/csgo_dash.png";
import rocketLeagueDashImg from "../assets/images/dashboard/rocket_league_dash.png";
import codDashImg from "../assets/images/dashboard/cod_dash.png";
import nbaDashImg from "../assets/images/dashboard/nba_dash.png";
import cricketDashImg from "../assets/images/dashboard/cricket_dash.png";
import kabaddiDashImg from "../assets/images/dashboard/kabaddi_dash.png";
import soccerDashImg from "../assets/images/dashboard/soccer_dash.png";

const gameImages = [
    { name: "lol", img: leagueDashImg, displayName: "League of Legends", label: "LOL" },
    { name: "dota2", img: dota2DashImg, displayName: "Dota 2", label: "DOTA2" },
    { name: "csgo", img: csgoDashImg, displayName: "CS:GO", label: "CS" },
    { name: "valorant", img: valDashImg, displayName: "Valorant", label: "VAL" },
    { name: "rocketleague", img: rocketLeagueDashImg, displayName: "Rocket League", label: "RL" },
    { name: "cod", img: codDashImg, displayName: "Call of Duty", label: "COD" },
    { name: "nba", img: nbaDashImg, displayName: "NBA", label: "NBA" },
    { name: "cricket", img: cricketDashImg, displayName: "Cricket", label: "CRICKET" },
    { name: "kabaddi", img: kabaddiDashImg, displayName: "Kabaddi", label: "KABADDI" },
    { name: "soccer", img: soccerDashImg, displayName: "Soccer", label: "SOCCER" },
];

const quickTimeFilterOptions = [
    { label: "Today", value: "today" },
    { label: "Next 5 Days", value: "next5days" },
    { label: "Last 5 Days", value: "last5days" },
];

const timezones = [
    { value: "UTC", label: "UTC" },
    { value: "America/New_York", label: "EST" },
    { value: "America/Los_Angeles", label: "PST" },
];

const downloadFullGameCSVPublic = async (gameType, matchId, rimbleApiKey, rimble3ApiKey) => {
    const convertToCSV = (gridData, columns, includeHeaders = true) => {
        if (!gridData || gridData.length === 0) {
            return "No data available\r\n";
        }

        let result = "";

        if (includeHeaders) {
            const columnHeaders = columns
                .filter(col => col && col.name)
                .map(col => col.name)
                .join(',');
            result += `${columnHeaders}\r\n`;
        }

        const csvRows = gridData.map(row => {
            return columns
                .filter(col => col && col.name)
                .map(col => {
                    let cellValue = row[col.name] || '';
                    cellValue = String(cellValue).replace(/"/g, '""');
                    return `"${cellValue}"`;
                })
                .join(',');
        });

        result += csvRows.join('\r\n') + '\r\n';
        return result;
    };

    const probabilityToAmericanOdds = (probability) => {
        if (typeof probability !== 'number' || isNaN(probability) || probability < 0 || probability > 1) {
            return 'N/A';
        }

        if (probability > 0.5) {
            return Math.round(-(probability / (1 - probability)) * 100);
        } else {
            return Math.round(((1 - probability) / probability) * 100);
        }
    };

    const calculateProbabilityFromDistribution = (goal, distribution) => {
        if (!distribution || !distribution.distribution || !distribution.bin_size ||
            distribution.start === undefined || distribution.end === undefined) {
            return 0.5;
        }

        if (goal <= distribution.start) return 0;
        if (goal >= distribution.end) return 1.0;

        const binSize = distribution.bin_size;
        const start = distribution.start;

        const binIndex = Math.floor((goal - start) / binSize);

        if (!distribution._cachedCumulativeDistribution) {
            const weights = distribution.distribution.map(Number);
            const totalWeight = weights.reduce((a, b) => a + b, 0);

            const cumulative = [];
            let sum = 0;
            for (let i = 0; i < weights.length; i++) {
                sum += weights[i];
                cumulative.push(sum / totalWeight);
            }

            distribution._cachedCumulativeDistribution = cumulative;
            distribution._cachedWeights = weights;
            distribution._cachedTotalWeight = totalWeight;
        }

        const normalizedCumulative = distribution._cachedCumulativeDistribution;
        const weights = distribution._cachedWeights;

        let cumulativeProbability = binIndex > 0 ? normalizedCumulative[binIndex - 1] : 0;
        const withinBinOffset = (goal - (start + binIndex * binSize)) / binSize;
        const binProbability = weights[binIndex] / distribution._cachedTotalWeight;

        cumulativeProbability += withinBinOffset * binProbability;
        return parseFloat(cumulativeProbability.toFixed(3));
    };

    const getAPIKeyForDownload = (game) => {
        return (game === "cricket" || game === "kabaddi") ? rimble3ApiKey : rimbleApiKey;
    };

    const fetchInitialData = async (gameTypeEncoded, matchIdEncoded, apiKey) => {
        const timeframeEncoded = "upcoming-matches";

        const response = await fetch(
            `https://rimbleanalytics.com/predictions/${gameTypeEncoded}/${timeframeEncoded}/?matchid=${matchIdEncoded}`,
            {
                method: "GET",
                headers: {
                    "x-api-key": apiKey,
                }
            }
        );

        if (!response.ok) {
            throw new Error(`API request failed with status: ${response.status}. Game type: ${gameTypeEncoded}, Match ID: ${matchIdEncoded}`);
        }

        return await response.json();
    };

    const getPlayers = (json, side) => {
        return (json.teams[side]?.players || []).map((player) => ({
            name: player.name,
            username: player.username || player.name,
            id: player.id,
            role: player.role || player.stats?.role,
            dob: player.DOB,
            image: player.image,
            projections: Array.isArray(player.projections) ? player.projections : [player.projections]
        }));
    };

    const getGameSpecificStatsForCSV = (player) => {
        const aggregatedProjectionsByMarket = {};

        for (const projection of (player.projections || [])) {
            if (!projection) continue;
            for (let index in projection) {
                if (index.includes("_prediction")) {
                    const marketName = index.substring(0, index.indexOf("_prediction"));
                    const predictionValue = projection[index];
                    const distributionVar = marketName + "_distribution";
                    const distribution = projection[distributionVar];
                    if (predictionValue !== "n/a" && !isNaN(parseFloat(predictionValue))) {
                        if (!aggregatedProjectionsByMarket[marketName]) {
                            aggregatedProjectionsByMarket[marketName] = {
                                predictions: [],
                                distributions: []
                            };
                        }
                        aggregatedProjectionsByMarket[marketName].predictions.push(parseFloat(predictionValue));
                        if (distribution) {
                            aggregatedProjectionsByMarket[marketName].distributions.push(distribution);
                        }
                    }
                }
            }
        }

        const predictionRows = [];
        const statsColumns = new Set(["type"]);

        for (const marketName in aggregatedProjectionsByMarket) {
            if (marketName) {
                statsColumns.add(marketName);
                const marketData = aggregatedProjectionsByMarket[marketName];

                if (!marketData || marketData.predictions.length === 0) {
                    predictionRows.push({ type: "prediction", [marketName]: "n/a" });
                    predictionRows.push({ type: "prediction_plus_bin", [marketName]: "n/a" });
                    predictionRows.push({ type: "prediction_minus_bin", [marketName]: "n/a" });
                    continue;
                }

                let pred;
                let original_pred;
                if (marketData.predictions.length === 1) {
                    original_pred = marketData.predictions[0];
                    pred = parseInt(original_pred) + 0.5;
                } else {
                    original_pred = marketData.predictions.reduce((sum, p) => sum + p, 0) / marketData.predictions.length;
                    pred = parseInt(original_pred) + 0.5;
                }

                const distributions = marketData.distributions;
                const hasDistributions = distributions.length > 0;

                if (hasDistributions) {
                    let totalOverProb = 0;
                    let totalUnderProb = 0;
                    let validProbabilityCount = 0;

                    for (const distribution of distributions) {
                        const cumulativeProb = calculateProbabilityFromDistribution(pred, distribution);
                        if (!isNaN(cumulativeProb)) {
                            totalOverProb += (1 - cumulativeProb);
                            totalUnderProb += cumulativeProb;
                            validProbabilityCount++;
                        }
                    }

                    let avgOverProb = 'N/A';
                    let avgUnderProb = 'N/A';

                    if (validProbabilityCount > 0) {
                        avgOverProb = (totalOverProb / validProbabilityCount).toFixed(2);
                        avgUnderProb = (totalUnderProb / validProbabilityCount).toFixed(2);
                    }

                    predictionRows.push({
                        type: "prediction",
                        [marketName]: `${pred.toFixed(1)} (O: ${avgOverProb}, U: ${avgUnderProb})`
                    });

                    // Reset counters for plus bin calculations
                    let totalPlusOverProb = 0;
                    let totalPlusUnderProb = 0;
                    let validPlusProbCount = 0;

                    // Reset counters for minus bin calculations
                    let totalMinusOverProb = 0;
                    let totalMinusUnderProb = 0;
                    let validMinusProbCount = 0;

                    for (const distribution of distributions) {
                        const binSize = distribution.bin_size;

                        // Calculate plus bin threshold and probabilities
                        let thresholdPlus = parseInt(original_pred + binSize) + 0.5;
                        let cumulativePlusProb = calculateProbabilityFromDistribution(thresholdPlus, distribution);
                        if (!isNaN(cumulativePlusProb)) {
                            totalPlusOverProb += (1 - cumulativePlusProb);
                            totalPlusUnderProb += cumulativePlusProb;
                            validPlusProbCount++;
                        }

                        // Calculate minus bin threshold and probabilities
                        let thresholdMinus = parseInt(original_pred - binSize) + 0.5;
                        let cumulativeMinusProb = calculateProbabilityFromDistribution(thresholdMinus, distribution);
                        if (!isNaN(cumulativeMinusProb)) {
                            totalMinusOverProb += (1 - cumulativeMinusProb);
                            totalMinusUnderProb += cumulativeMinusProb;
                            validMinusProbCount++;
                        }
                    }

                    let avgPlusOverProb = 'N/A';
                    let avgPlusUnderProb = 'N/A';
                    let avgMinusOverProb = 'N/A';
                    let avgMinusUnderProb = 'N/A';

                    if (validPlusProbCount > 0) {
                        avgPlusOverProb = (totalPlusOverProb / validPlusProbCount).toFixed(2);
                        avgPlusUnderProb = (totalPlusUnderProb / validPlusProbCount).toFixed(2);
                    }

                    if (validMinusProbCount > 0) {
                        avgMinusOverProb = (totalMinusOverProb / validMinusProbCount).toFixed(2);
                        avgMinusUnderProb = (totalMinusUnderProb / validMinusProbCount).toFixed(2);
                    }

                    // Use original_pred for calculating the display values to match the threshold calculations
                    const plusBinValue = parseInt(original_pred + distributions[0].bin_size) + 0.5;
                    const minusBinValue = parseInt(original_pred - distributions[0].bin_size) + 0.5;

                    predictionRows.push({
                        type: "prediction_plus_bin",
                        [marketName]: `${plusBinValue.toFixed(1)} (O: ${avgPlusOverProb}, U: ${avgPlusUnderProb})`
                    });

                    predictionRows.push({
                        type: "prediction_minus_bin",
                        [marketName]: `${minusBinValue.toFixed(1)} (O: ${avgMinusOverProb}, U: ${avgMinusUnderProb})`
                    });

                } else {
                    predictionRows.push({
                        type: "prediction",
                        [marketName]: `${pred.toFixed(1)} (No Prob)`
                    });
                    predictionRows.push({ type: "prediction_plus_bin", [marketName]: "n/a" });
                    predictionRows.push({ type: "prediction_minus_bin", [marketName]: "n/a" });
                }
            }
        }

        return predictionRows;
    };

    const processTeamStatsForCSV = (teamPlayers, playerStatsData, teamName) => {
        const playerDataRows = [];

        for (const player of teamPlayers) {
            const playerUsername = player.username;
            const playerRawData = playerStatsData[playerUsername];

            if (!playerRawData || !playerRawData.data) continue;

            const playerStat = playerRawData.data;
            if (!playerStat || !playerStat.gridData) continue;

            playerStat.gridData.forEach(rowDataByType => {
                for (const market in rowDataByType) {
                    if (market === 'type') continue;
                    const rowType = rowDataByType.type;
                    const statName = market;
                    const projectionData = playerStat.player;
                    let overProb = 'N/A';
                    let underProb = 'N/A';
                    let targetLineValue = rowDataByType[market];

                    if (projectionData && projectionData.projections && projectionData.projections.length > 0) {
                        const distributionData = projectionData.projections
                            .map(projection => {
                                let distribution = projection[`${statName}_distribution`];
                                if (distribution === undefined) {
                                    distribution = projection[`${statName.toUpperCase()}_distribution`];
                                }
                                if (distribution === undefined) {
                                    distribution = projection[`${statName.toLowerCase()}_distribution`];
                                }
                                return distribution ? { distribution } : null;
                            })
                            .filter(data => data !== null);

                        if (distributionData.length > 0) {
                            let lineValue;
                            if (typeof targetLineValue === 'string' && targetLineValue.includes('(')) {
                                lineValue = parseFloat(targetLineValue.split(' ')[0]);
                            } else if (typeof targetLineValue !== 'string') {
                                lineValue = parseFloat(targetLineValue);
                            } else {
                                lineValue = parseFloat(targetLineValue);
                            }

                            if (!isNaN(lineValue)) {
                                let totalOverProb = 0;
                                let totalUnderProb = 0;
                                let validDistCount = 0;

                                for (const data of distributionData) {
                                    const cumulativeProb = calculateProbabilityFromDistribution(lineValue, data.distribution);
                                    if (!isNaN(cumulativeProb)) {
                                        totalOverProb += (1 - cumulativeProb);
                                        totalUnderProb += cumulativeProb;
                                        validDistCount++;
                                    }
                                }

                                if (validDistCount > 0) {
                                    overProb = (totalOverProb / validDistCount).toFixed(3);
                                    underProb = (totalUnderProb / validDistCount).toFixed(3);
                                    targetLineValue = lineValue; // Use the extracted line value for CSV
                                }
                            }
                        }
                    }

                    let extractedTargetLine = 'N/A';
                    if (typeof targetLineValue === 'string') {
                        extractedTargetLine = targetLineValue ? targetLineValue.split(' ')[0] : 'N/A';
                    } else {
                        extractedTargetLine = targetLineValue;
                    }

                    if (extractedTargetLine > 0 && extractedTargetLine !== 'N/A' && targetLineValue !== 'n/a') {
                        playerDataRows.push({
                            'Player Username': player.username || 'N/A',
                            'Player Role': player.role || 'N/A',
                            'Player Name': player.name || 'N/A',
                            'Team': teamName,
                            'Row Type': rowType,
                            'Market': market,
                            'Target Line': extractedTargetLine,
                            'Over Unmarginated American Odds': probabilityToAmericanOdds(parseFloat(overProb)),
                            'Under Unmarginated American Odds': probabilityToAmericanOdds(parseFloat(underProb)),
                            'Injury Status': 'None'
                        });
                    }
                }
            });
        }
        return playerDataRows;
    };

    try {
        const gameTypeEncoded = encodeURIComponent(gameType);
        const matchIdEncoded = encodeURIComponent(matchId);
        const apiKey = getAPIKeyForDownload(gameTypeEncoded);

        const initialData = await fetchInitialData(gameTypeEncoded, matchIdEncoded, apiKey);
        if (!initialData || !Array.isArray(initialData) || initialData.length === 0) {
            throw new Error(`No data received from API for game type: ${gameType}, match ID: ${matchId}`);
        }

        const predictionData = initialData[0]; // Assuming first element is relevant match

        const team1Players = getPlayers(predictionData, 0);
        const team2Players = getPlayers(predictionData, 1);

        const team1Name = predictionData.teams[0]?.name;
        const team2Name = predictionData.teams[1]?.name;

        // Process team 1 stats
        const team1StatsData = {};
        for (const player of team1Players) {
            const gameStats = getGameSpecificStatsForCSV(player);
            team1StatsData[player.username] = {
                data: {
                    gridData: gameStats,
                    player: player,
                }
            };
        }

        // Process team 2 stats
        const team2StatsData = {};
        for (const player of team2Players) {
            const gameStats = getGameSpecificStatsForCSV(player);
            team2StatsData[player.username] = {
                data: {
                    gridData: gameStats,
                    player: player,
                }
            };
        }

        // CSV column definitions
        const playerCSVColumns = [
            { name: 'Player Username' },
            { name: 'Player Role' },
            { name: 'Player Name' },
            { name: 'Team' },
            { name: 'Row Type' },
            { name: 'Market' },
            { name: 'Target Line' },
            { name: 'Over Unmarginated American Odds' },
            { name: 'Under Unmarginated American Odds' },
            { name: 'Injury Status' }
        ];

        // Generate final CSV
        let fullCSVData = "";
        const team1PlayerDataRows = processTeamStatsForCSV(team1Players, team1StatsData, team1Name);
        fullCSVData += convertToCSV(team1PlayerDataRows, playerCSVColumns);

        const team2PlayerDataRows = processTeamStatsForCSV(team2Players, team2StatsData, team2Name);
        fullCSVData += convertToCSV(team2PlayerDataRows, playerCSVColumns, false);

        return fullCSVData;

    } catch (error) {
        console.error("Error generating CSV data:", error);
        return `Error generating CSV data: ${error.message}`;
    }
};

const Dashboard = (props) => {
    const [userId, setUserId] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordAuthenticated, setPasswordAuthenticated] = useState(false);
    const [authenticationMessage, setAuthenticationMessage] = useState("Please enter password...");
    const [games, setGames] = useState([]);
    const [gamesDataLoaded, setGamesDataLoaded] = useState(false);
    const [gameType, setGameType] = useState("");
    const [timeframe, setTimeframe] = useState("");
    const [loadingData, setLoadingData] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [timezone, setTimezone] = useState("UTC");
    const [timezoneLabel, setTimezoneLabel] = useState("UTC"); // New state for timezone label - for display
    const [minDate, setMinDate] = useState("");
    const [maxDate, setMaxDate] = useState("");
    const [quickTimeFilter, setQuickTimeFilter] = useState("today");
    const [downloadingCSV, setDownloadingCSV] = useState(false);
    const [selectedMatchIdForDownload, setSelectedMatchIdForDownload] = useState(null);
    const [subscribingGame, setSubscribingGame] = useState(null);

    const isInitialMount = useRef(true);

    const handleNavigation = useCallback((path) => {
        props.navigateAction(path);
        props.navigate(path);
    }, [props.navigateAction, props.navigate]);

    const getAPIKey = useCallback((game) => {
        return (game === "cricket" || game === "kabaddi")
            ? process.env.REACT_APP_RIMBLE3_API_KEY
            : process.env.REACT_APP_RIMBLE_API_KEY;
    }, []);

    const getDownloadAPIKey = useCallback((game) => { // API key for download function
        return (game === "cricket" || game === "kabaddi")
            ? process.env.REACT_APP_RIMBLE3_API_KEY
            : process.env.REACT_APP_RIMBLE_API_KEY;
    }, []);


    const updateGameType = useCallback((game) => {
        setGameType(game);
        setTimeframe("upcoming-matches");
        setGames([]);
        setGamesDataLoaded(false);
        setApiError(null);
    }, [gameType]);

    // Define displayDateTime BEFORE updateTimeFrame
    const displayDateTime = useCallback((date, time) => {
        if (!date || !time) {
            return { formattedDate: 'N/A', formattedTime: 'N/A' };
        }

        let convertedDateTime;
        try {
            // Parse date and time SEPARATELY as UTC, using CORRECT FORMATS
            const utcDateTime = moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss', 'UTC');


            if (!utcDateTime.isValid()) {
                return { formattedDate: 'Invalid Date', formattedTime: 'Invalid Time' };
            }
            convertedDateTime = utcDateTime.tz(timezone);


        } catch (e) {
            return { formattedDate: 'Invalid Date', formattedTime: 'Invalid Time' };
        }

        const formattedDate = convertedDateTime.format("YYYY-MM-DD"); // Simplified Date format for now
        const formattedTime = convertedDateTime.format('HH:mm:ss'); // Simplified Time format - 24-hour

        return {
            formattedDate: formattedDate, // Return simplified formatted date string
            formattedTime: formattedTime, // Return simplified formatted time string
        };
    }, [timezone]);


    const updateGamesTimezone = useCallback(() => {
        setGames(prevGames => { // Use functional state update for safety
            if (!prevGames || prevGames.length === 0) {
                return prevGames; // No games to update, return existing state
            }

            const updatedGames = prevGames.map(game => {
                const originalDate = game.date; // Log original date
                const originalTime = game.time; // Log original time

                const dateTimeFormats = displayDateTime(originalDate, originalTime); // Call displayDateTime to get both date and time
                return {
                    ...game, // Copy all existing game properties
                    date: dateTimeFormats.formattedDate, // Use CONVERTED date
                    time: dateTimeFormats.formattedTime, // Use CONVERTED time
                };
            });
            return updatedGames; // Return the new array with updated games
        });
    }, [timezone, displayDateTime]);


    const updateTimeFrame = useCallback(async (time) => {
        if (!gameType) {
            console.log("updateTimeFrame EARLY EXIT - No gameType selected yet.");
            return;
        }

        setTimeframe(time);
        setGamesDataLoaded(false);
        setLoadingData(true);
        setApiError(null);

        let fetchedGames = [];

        if (quickTimeFilter === "next5days") {
          for (let i = 0; i < 5; i++) {
              const currentDate = moment().add(i, 'days').format('YYYY-MM-DD');
              let apiUrl = `https://rimbleanalytics.com/predictions/${gameType}/${time}/?date=${currentDate}`;
              if (gameType === "nba" || gameType === "soccer") {
                  apiUrl = `https://kvru1gtful.execute-api.us-east-1.amazonaws.com/prod/?game=${gameType}`;
              }

              try {
                  const res = await fetch(apiUrl, {
                      method: "GET",
                      headers: {
                          "x-api-key": getAPIKey(gameType),
                      },
                  });

                  if (!res.ok) {
                      const message = `HTTP error! status: ${res.status} for date: ${currentDate}`;
                      console.error("updateTimeFrame - API Error:", message);
                      throw new Error(message);
                  }

                  const json = await res.json();
                  let processedGames;

                  if (gameType === "nba" || gameType === "soccer") {
                      processedGames = json.Items.map(match => ({
                          matchid: match.gameid,
                          date: match.date,
                          time: match.time,
                          league: gameType.toUpperCase(),
                          team_1_name: match.team_1_name,
                          team_2_name: match.team_2_name,
                          teams: match.teams,
                          button: _(
                              <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.gameid}`, { timezone: timezone })}>
                                  View Match Info
                              </Nav.Link>
                          ),
                          downloadButton: _(
                              <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.gameid)}>Download</Button>
                          )
                      }));
                  } else if (gameType === "cod") {
                      processedGames = json.map(match => ({
                          matchid: match.matchid,
                          date: match.date,
                          time: match.time,
                          league: match.league,
                          team_1_name: match.team_1_name,
                          team_2_name: match.team_2_name,
                          teams: match.teams,
                          downloadButton: _(
                              <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.matchid)}>Download</Button>
                          ),
                          subscribeButton: _(
                              <Button size="sm" onClick={() => handleSubscribe(gameType, match.matchid)}>Sub</Button>
                          )
                      }));
                  } else {
                      processedGames = json.map(match => ({
                          matchid: match.matchid,
                          date: match.date,
                          time: match.time,
                          league: match.league,
                          team_1_name: match.team_1_name,
                          team_2_name: match.team_2_name,
                          teams: match.teams,
                          button: _(
                              <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.matchid}`, { timezone: timezone })}>
                                  View Match Info
                              </Nav.Link>
                          ),
                          downloadButton: _(
                              <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.matchid)}>Download</Button>
                          ),
                          subscribeButton: _(
                              <Button size="sm" onClick={() => handleSubscribe(gameType, match.matchid)}>Sub</Button>
                          )
                      }));
                  }
                  fetchedGames = fetchedGames.concat(processedGames);
              } catch (error) {
                  console.error("updateTimeFrame - Error during Next 5 Days fetch (loop):", error);
              }
          }
          console.log(`updateTimeFrame - Next 5 Days fetch complete, total games fetched: ${fetchedGames.length}`);
      } else if (quickTimeFilter === "last5days") {
          for (let i = 1; i <= 5; i++) {
              const currentDate = moment().subtract(i, 'days').format('YYYY-MM-DD');
              let apiUrl = `https://rimbleanalytics.com/predictions/${gameType}/${time}/?date=${currentDate}`;
              if (gameType === "nba" || gameType === "soccer") {
                  apiUrl = `https://kvru1gtful.execute-api.us-east-1.amazonaws.com/prod/?game=${gameType}`;
              }

              try {
                  const res = await fetch(apiUrl, {
                      method: "GET",
                      headers: {
                          "x-api-key": getAPIKey(gameType),
                      },
                  });

                  if (!res.ok) {
                      const message = `HTTP error! status: ${res.status} for date: ${currentDate}`;
                      console.error("updateTimeFrame - API Error:", message);
                      throw new Error(message);
                  }

                  const json = await res.json();
                  let processedGames;

                  if (gameType === "nba" || gameType === "soccer") {
                      processedGames = json.Items.map(match => ({
                          matchid: match.gameid,
                          date: match.date,
                          time: match.time,
                          league: gameType.toUpperCase(),
                          team_1_name: match.team_1_name,
                          team_2_name: match.team_2_name,
                          teams: match.teams,
                          button: _(
                              <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.gameid}`, { timezone: timezone })}>
                                  View Match Info
                              </Nav.Link>
                          ),
                          downloadButton: _(
                              <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.gameid)}>Download</Button>
                          )
                      }));
                  } else {
                      processedGames = json.map(match => ({
                          matchid: match.matchid,
                          date: match.date,
                          time: match.time,
                          league: match.league,
                          team_1_name: match.team_1_name,
                          team_2_name: match.team_2_name,
                          teams: match.teams,
                          button: _(
                              <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.matchid}`, { timezone: timezone })}>
                                  View Match Info
                              </Nav.Link>
                          ),
                          downloadButton: _(
                              <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.matchid)}>Download</Button>
                          )
                      }));
                  }

                  fetchedGames = fetchedGames.concat(processedGames);
              } catch (error) {
                  console.error("updateTimeFrame - Error during Last 5 Days fetch (loop):", error);
              }
          }
          console.log(`updateTimeFrame - Last 5 Days fetch complete, total games fetched: ${fetchedGames.length}`);
      } else if (minDate && maxDate && quickTimeFilter === "custom") {
            const startDate = moment(minDate);
            const endDate = moment(maxDate);
            const diffDays = endDate.diff(startDate, 'days') + 1; // include both dates

            for (let i = 0; i < diffDays; i++) {
                const currentDate = moment(minDate).add(i, 'days').format('YYYY-MM-DD');
                let apiUrl = `https://rimbleanalytics.com/predictions/${gameType}/${time}/?date=${currentDate}`;
                if (gameType === "nba" || gameType === "soccer") {
                    apiUrl = `https://kvru1gtful.execute-api.us-east-1.amazonaws.com/prod/?game=${gameType}`;
                }

                try {
                    const res = await fetch(apiUrl, {
                        method: "GET",
                        headers: {
                            "x-api-key": getAPIKey(gameType),
                        },
                    });

                    if (!res.ok) {
                        const message = `HTTP error! status: ${res.status} for date: ${currentDate} (Custom Range)`;
                        console.error("updateTimeFrame - API Error:", message);
                        throw new Error(message);
                    }

                    const json = await res.json();
                    let processedGames;

                    if (gameType === "nba" || gameType === "soccer") {
                        processedGames = json.Items.map(match =>  { // Changed to block body and explicit return
                            return {
                                matchid: match.gameid,
                                date: match.date, // DO NOT CONVERT DATE HERE - keep API date
                                time: 'N/A',
                                league: gameType.toUpperCase(),
                                team_1_name: match.team_1_name,
                                team_2_name: match.team_2_name,
                                teams: match.teams,
                                button: _(
                                    <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.gameid}`, { timezone: timezone })}>
                                        View Match Info
                                    </Nav.Link>
                                ),
                                downloadButton: _(
                                    <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.gameid)}>Download</Button>
                                )
                            };
                        });
                    } else {
                        processedGames = json.map(match =>  { // Changed to block body and explicit return
                            return {
                                matchid: match.matchid,
                                date: match.date, // DO NOT CONVERT DATE HERE - keep API date
                                time: match.time, // DO NOT CONVERT TIME HERE - keep API time
                                league: match.league,
                                team_1_name: match.team_1_name,
                                team_2_name: match.team_2_name,
                                teams: match.teams,
                                button: _(
                                    <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.matchid}`, { timezone: timezone })}>
                                        View Match Info
                                    </Nav.Link>
                                ),
                                downloadButton: _(
                                    <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.matchid)}>Download</Button>
                                ),
                                subscribeButton: _(
                                    <Button size="sm" onClick={() => handleSubscribe(gameType, match.matchid)}>Sub</Button>
                                )
                            };
                        });
                    }
                    fetchedGames = fetchedGames.concat(processedGames);
                } catch (error) {
                    console.error("updateTimeFrame - Error during Custom Date Range fetch (loop):", error);
                }
            }
            console.log(`updateTimeFrame - Custom Date Range fetch complete, total games fetched: ${fetchedGames.length}`);
        } else {
            const currentDate = moment().format('YYYY-MM-DD');
            let apiUrl = `https://rimbleanalytics.com/predictions/${gameType}/${time}/?date=${currentDate}`;
            if (gameType === "nba" || gameType === "soccer") {
                apiUrl = `https://kvru1gtful.execute-api.us-east-1.amazonaws.com/prod/?game=${gameType}`;
            }

            try {
                const res = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "x-api-key": getAPIKey(gameType),
                    },
                });

                if (!res.ok) {
                    const message = `HTTP error! status: ${res.status}`;
                    console.error("updateTimeFrame - API Error:", message);
                    throw new Error(message);
                }

                const json = await res.json();
                let processedGames = json.map(match =>  { // Changed to block body and explicit return
                    return {
                        matchid: match.matchid,
                        date: match.date, // DO NOT CONVERT DATE HERE - keep API date
                        time: match.time, // DO NOT CONVERT TIME HERE - keep API time
                        league: match.league,
                        team_1_name: match.team_1_name,
                        team_2_name: match.team_2_name,
                        teams: match.teams,
                        button: _(
                            <Nav.Link onClick={() => handleNavigation(`/teams/${gameType}/${match.team_1_name}/${match.team_2_name}/${time}/${match.matchid}`, { timezone: timezone })}>
                                View Match Info
                            </Nav.Link>
                        ),
                        downloadButton: _(
                            <Button size="sm" onClick={() => handleDownloadCSV(gameType, match.matchid)}>Download</Button>
                        ),
                        subscribeButton: _(
                            <Button size="sm" onClick={() => handleSubscribe(gameType, match.matchid)}>Sub</Button>
                        )
                    };
                });
                fetchedGames = processedGames;
                console.log("updateTimeFrame END - Data fetched and state updated for single day fetch");
            } catch (error) {
                console.error("updateTimeFrame CATCH - API Fetch Error (single day):", error);
                setGamesDataLoaded(false);
                setLoadingData(false);
                setApiError("Failed to load game data. Please try again later.");
            }
        }

        setGames([...fetchedGames]); // Force re-render by creating a new array reference
        setGamesDataLoaded(true);
        setLoadingData(false);
        setApiError(null);
    }, [gameType, getAPIKey, handleNavigation, timeframe, minDate, maxDate, quickTimeFilter, displayDateTime, getDownloadAPIKey]);

    const handleDownloadCSV = async (currentGameType, matchId) => {
        console.warn(currentGameType, matchId)
        if (!currentGameType || !matchId) {
            console.error("Game type and Match ID are required for CSV download.");
            return;
        }

        setDownloadingCSV(true);
        try {
            const apiKey = getDownloadAPIKey(currentGameType); // Use download specific API key
            const csvData = await downloadFullGameCSVPublic(currentGameType, matchId, apiKey, process.env.REACT_APP_RIMBLE3_API_KEY);

            if (typeof csvData === 'string' && csvData.startsWith("Error generating CSV data")) {
                console.error("CSV Download Error:", csvData);
                setApiError(csvData);
            } else {
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = `${currentGameType}_match_${matchId}_data.csv`; // Dynamic filename
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(url);
                setApiError(null);
            }
        } catch (error) {
            console.error("CSV Download Error:", error);
            setApiError(`CSV Download Failed: ${error.message}`);
        } finally {
            setDownloadingCSV(false);
        }
    };


    const handleSubscribe = async (currentGameType, matchId) => {
        console.warn(currentGameType, matchId)
        if (!currentGameType || !matchId) {
            console.error("Game type and Match ID are required for subscription.");
            return;
        }

        setSubscribingGame(matchId);
        setApiError(null);

        try {
            const apiUrl = "https://77zvwraq5d.execute-api.us-east-1.amazonaws.com/production/subscribe";
            const apiKey = process.env.REACT_APP_RIMBLE_DASHBOARD_API_KEY
            const storedUserId = localStorage.getItem('userId');


            const payload = {
                gameid: matchId,
                gametype: currentGameType,
                username: storedUserId
            };

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "x-api-key": apiKey,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload),
                mode: "cors"
            });

            if (!response.ok) {
                const message = `Subscription failed. HTTP error! status: ${response.status}`;
                console.error("Subscription API Error:", message);
                throw new Error(message);
            }

            const responseData = await response.json();
            alert("Subscription successful!");
        } catch (error) {
            console.error("Subscription Error:", error);
            setApiError(`Subscription Failed: ${error.message}`);
        } finally {
            setSubscribingGame(null);
        }
    };

    const handleSubmit = useCallback(() => {
        const hashObj = new jsSHA("SHA-512", "TEXT", { numRounds: 1 });
        hashObj.update(password);
        const hash = hashObj.getHash("HEX");

        const dashboardPasswords = process.env.REACT_APP_DASHBOARD_PASSWORDS.split(", ");

        if (dashboardPasswords.includes(hash)) {
            setAuthenticationMessage("Success! Logging in...");
            setPasswordAuthenticated(true);
            const passwordHashUserId = hash;
            localStorage.setItem('userId', passwordHashUserId);
            setUserId(passwordHashUserId);
        } else {
            setAuthenticationMessage("Incorrect password. Please try again...");
            setPasswordAuthenticated(false);
        }
    }, [password]);

    const handlePasswordInputChange = useCallback((event) => {
        setPassword(event.target.value);
        setAuthenticationMessage("Please enter password...");
    }, []);

    const handleTimezoneSelect = (tzValue) => {
        const selectedTimezone = timezones.find(tz => tz.value === tzValue);
        if (selectedTimezone) {
            setTimezone(tzValue);
            setTimezoneLabel(selectedTimezone.label);
        }
    };


    const listDates = useCallback((gamesList) => {
        return [...new Set(gamesList.map(item => item.date))];
    }, []);

    const matchDate = useCallback((matchdate) => {
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${year}-${months[month]}-${day}`;

        if (matchdate === formattedDate) {
            return "TODAY";
        } else {
            if (timeframe === "upcoming-matches") return "UPCOMING";
            if (timeframe === "completed-matches") return "COMPLETED";
            if (timeframe === "live-matches") return "LIVE";
        }
        return null;
    }, [timeframe]);

    useEffect(() => {
        const storedState = window.sessionStorage.getItem('dashboardState');
        const storedUserId = localStorage.getItem('userId'); // Retrieve userId (now password hash)

        if (storedUserId) {
            setUserId(storedUserId);
            const userSettings = localStorage.getItem(`userSettings_${storedUserId}`);
            if (userSettings) {
                const parsedSettings = JSON.parse(userSettings);
                setGameType(parsedSettings.gameType || "");
                setTimeframe(parsedSettings.timeframe || "");
                setTimezone(parsedSettings.timezone || "UTC");
                setTimezoneLabel(parsedSettings.timezoneLabel || "UTC");
                setMinDate(parsedSettings.minDate || "");
                setMaxDate(parsedSettings.maxDate || "");
                setQuickTimeFilter(parsedSettings.quickTimeFilter || "today");
                setPasswordAuthenticated(true); // Automatically authenticate if userId is present
            } else if (storedState) { // Fallback to sessionStorage if no userSettings in localStorage
                const parsedState = JSON.parse(storedState);
                setPasswordAuthenticated(parsedState.passwordAuthenticated || false);
                setGameType(parsedState.gameType || "");
                setTimeframe(parsedState.timeframe || "");
                setTimezone(parsedState.timezone || "UTC");
                setTimezoneLabel(parsedState.timezoneLabel || "UTC");
                setMinDate(parsedState.minDate || "");
                setMaxDate(parsedState.maxDate || "");
                setQuickTimeFilter(parsedState.quickTimeFilter || "today");
            }
        } else if (storedState) { // Fallback to sessionStorage if no userId in localStorage
            const parsedState = JSON.parse(storedState);
            setPasswordAuthenticated(parsedState.passwordAuthenticated || false);
            setGameType(parsedState.gameType || "");
            setTimeframe(parsedState.timeframe || "");
            setTimezone(parsedState.timezone || "UTC");
            setTimezoneLabel(parsedState.timezoneLabel || "UTC");
            setMinDate(parsedState.minDate || "");
            setMaxDate(parsedState.maxDate || "");
            setQuickTimeFilter(parsedState.quickTimeFilter || "today");
        }
        isInitialMount.current = false;
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            return;
        }
        const stateToStore = {
            passwordAuthenticated,
            gameType,
            timeframe,
            timezone,
            timezoneLabel,
            minDate,
            maxDate,
            quickTimeFilter,
        };
        window.sessionStorage.setItem('dashboardState', JSON.stringify(stateToStore));

        if (userId) {
            const userSettingsToStore = {
                gameType,
                timeframe,
                timezone,
                timezoneLabel,
                minDate,
                maxDate,
                quickTimeFilter,
            };
            localStorage.setItem(`userSettings_${userId}`, JSON.stringify(userSettingsToStore));
        }
    }, [passwordAuthenticated, gameType, timeframe, timezone, timezoneLabel, minDate, maxDate, quickTimeFilter, userId]);

    useEffect(() => {
        if (gameType && timeframe) {
            updateTimeFrame(timeframe);
        } else {
            setGames([]);
            setGamesDataLoaded(false);
        }
    }, [gameType, timeframe, updateTimeFrame, minDate, quickTimeFilter]);

    if (!passwordAuthenticated) {
        return (
            <Layout>
                <Container className="dashboard-auth-container">
                    <h3 className="auth-message">{authenticationMessage}</h3>
                    <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                        <Form.Control
                            type="password"
                            className="auth-input"
                            value={password}
                            onChange={handlePasswordInputChange}
                            placeholder="Enter your password"
                        />
                        <Button className="auth-submit-btn" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    const renderGameTypeSelection = () => (
        <Nav className="flex-column game-nav">
            {gameImages.map((game, index) => (
                <Nav.Link
                    key={index}
                    onClick={() => updateGameType(game.name)}
                    active={gameType === game.name}
                    className={`game-nav-link ${gameType === game.name ? 'active-game' : ''}`}
                >
                    {game.label}
                </Nav.Link>
            ))}
        </Nav>
    );

    const renderFilterBar = () => {
        return (
            <Row className="filter-bar mb-3">
                <Col xs="auto" className="filter-section filter-col me-3 d-flex flex-column align-items-start">
                    <strong className="filter-header">QUICK TIME FILTERS</strong>
                    <Form.Select
                        size="sm"
                        className="filter-control"
                        value={quickTimeFilter}
                        onChange={e => {
                            setQuickTimeFilter(e.target.value);
                            setMinDate("");
                            setMaxDate("");
                            updateTimeFrame(timeframe);
                        }}
                    >
                        {quickTimeFilterOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Form.Select>
                </Col>

                <Col xs="auto" className="filter-section filter-col me-3 d-flex flex-column align-items-start">
                    <strong className="filter-header">COMPETITION STATE FILTERS</strong>
                    <Form.Select
                        size="sm"
                        className="filter-control"
                        value={timeframe}
                        onChange={e => {
                            setTimeframe(e.target.value);
                            updateTimeFrame(e.target.value);
                        }}
                    >
                        <option value="upcoming-matches">Upcoming</option>
                        <option value="completed-matches">Completed</option>
                        <option value="live-matches">Live</option>
                    </Form.Select>
                </Col>

                <Col xs="auto" className="filter-section filter-col timezone-col ms-auto d-flex flex-column align-items-start">
                    <strong className="filter-header">Timezone</strong>
                    <Dropdown
                        onToggle={(isOpen) => {}}
                        onSelect={handleTimezoneSelect}
                        autoClose="true"
                    >
                        <Dropdown.Toggle
                            variant="light"
                            size="sm"
                            className="timezone-dropdown-toggle filter-control"
                            id="timezone-dropdown-toggle"
                        >
                            {timezoneLabel}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="timezone-dropdown-menu">
                            {timezones.map(tz => (
                                <Dropdown.Item eventKey={tz.value} key={tz.value} className="timezone-dropdown-item">
                                    {tz.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

                <Col xs="auto" className="filter-section filter-col me-3 d-flex flex-column align-items-start">
                    <strong className="filter-header">CUSTOM TIME FILTER</strong>
                    <div className="date-input-group d-flex align-items-baseline">
                        <Form.Control
                            size="sm"
                            type="date"
                            placeholder="Min Date"
                            className="filter-control date-input mr-2"
                            value={minDate}
                            onChange={e => { setMinDate(e.target.value); setQuickTimeFilter("custom"); }}
                        />
                        <Form.Control
                            size="sm"
                            type="date"
                            placeholder="Max Date"
                            className="filter-control date-input mr-2"
                            value={maxDate}
                            onChange={e => setMaxDate(e.target.value)}
                        />
                        <Button variant="primary" size="sm" className="filter-control fetch-button custom-fetch-button-spacing" onClick={() => updateTimeFrame(timeframe)}>Fetch Now</Button>
                    </div>
                </Col>
                <Col xs="auto" className="filter-section filter-col d-flex align-items-start">
                    <strong className="filter-header"> </strong> {/* For vertical alignment */}
                    <Button
                        variant="success"
                        size="sm"
                        className="filter-control download-button"
                        onClick={() => {
                            if (games.length > 0) {
                                games.forEach((game) => {
                                    handleDownloadCSV(gameType, game.matchid);
                                });
                            } else {
                                alert("No games loaded to download. Please fetch game data first.");
                            }
                        }}
                        disabled={loadingData || downloadingCSV || games.length === 0}
                    >
                        {downloadingCSV ? <Spinner animation="border" size="sm" /> : "Quick Download"}
                    </Button>
                </Col>
            </Row>
        );
    };


    return (
        <Layout>
            <Container fluid className="dashboard-container">
                <Row className="dashboard-content-row">
                    <Col md={2} className="game-nav-col">
                        {renderGameTypeSelection()}
                    </Col>
                    <Col md={10} className="data-area">
                        {renderFilterBar()}
                        {apiError && <p className="error-message">{apiError}</p>}
                        <div className="dash-table">
                            <div className="table-container">
                            <Grid
                              className="dash-data"
                              data={games.map(game => {
                                const dateTimeFormats = displayDateTime(game.date, game.time, timezone);
                                return {
                                  matchid: game.matchid,
                                  date: dateTimeFormats.formattedDate,
                                  time: dateTimeFormats.formattedTime,
                                  league: game.league,
                                  team_1_name: game.team_1_name,
                                  team_2_name: game.team_2_name,
                                  button: game.button,
                                  downloadButton: game.downloadButton,
                                  subscribeButton: game.subscribeButton,
                                };
                              })}
                              columns={[
                                {
                                  id: 'matchid',
                                  name: 'MATCHID',
                                  width: '10%',
                                  sort: true
                                },
                                {
                                  id: 'date',
                                  name: 'DATE',
                                  width: '10%',
                                  sort: true
                                },
                                {
                                  id: 'time',
                                  name: 'TIME',
                                  width: '10%',
                                  sort: true
                                },
                                {
                                  id: 'league',
                                  name: 'LEAGUE',
                                  width: '15%',
                                  sort: false
                                },
                                {
                                  id: 'team_1_name',
                                  name: 'TEAM 1',
                                  width: '15%',
                                  sort: false
                                },
                                {
                                  id: 'team_2_name',
                                  name: 'TEAM 2',
                                  width: '15%',
                                  sort: false
                                },
                                {
                                  id: 'button',
                                  name: 'Match Info',
                                  width: '10%',
                                  sort: false
                                },
                                {
                                  id: 'downloadButton',
                                  name: '',
                                  width: '9%',
                                  sort: false
                                },
                                {
                                  id: 'subscribeButton',
                                  name: '',
                                  width: '7%',
                                  sort: false
                                }
                              ]}
                              sort={true}
                              resizable={false}
                              pagination={{
                                limit: 30,
                                summary: false,
                              }}
                              className="dash-data"
                              style={{
                                tableLayout: 'fixed',
                                width: '100%'
                              }}
                            />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

const mapDispatchToProps = (dispatch) => ({
    navigateAction: (path) => dispatch(navigateAction(path)),
});

export default connect(null, mapDispatchToProps)(withNavigation(Dashboard));
