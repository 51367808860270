import { Container, Col, Row } from 'react-bootstrap';
import "../HowItWorks/css/how-it-works.css";
import game from "../../../assets/images/how_it_works/games.png";
import computer_vision from "../../../assets/images/how_it_works/computer_vision.png";
import ml_models from "../../../assets/images/how_it_works/ml_models.png";
import raw_data from "../../../assets/images/how_it_works/raw_data_and_analytics.png";
import right_arrow from "../../../assets/images/how_it_works/right.png";
import down_arrow from "../../../assets/images/how_it_works/down.png";
import { Helmet } from 'react-helmet';
import "@fontsource/dm-sans";

function HowItWorks() {
    return (
        <div className="how-it-works-section" id="how-it-works-section">
            <Helmet>
                <meta name="description" content="Discover how Rimble's AI platform delivers real-time esports data and analytics. Learn about our process: from game data capture using computer vision to machine learning models and raw data output." />
            </Helmet>
            <Container className="how-it-works-container">
                <Row className="how-header-row text-center">
                    <Col xs={12}>
                        <h2 className="how-section-header">HOW IT WORKS</h2>
                        <p className="how-section-subheader">Esports Data Reimagined</p>
                        <p className='how-section-description'>
                            Rimble's platform ingests vast amounts of historical and live game data, leveraging proprietary computer vision.
                            Our custom-built machine learning and automation then transform this data into advanced analytics and real-time data feeds for granular esports events.
                        </p>
                    </Col>
                </Row>
                <Row className="how-it-works-icons-row justify-content-center">
                    <Col className="icon-step-col icon-step">
                        <div className='step-img-container'>
                            <img className="step-icon game-icon" src={game} loading="lazy" decoding="async" alt="Esports Game Data Input" />
                        </div>
                        <p className="icon-step-desc game-text">Game Data</p>
                    </Col>
                    <Col className="arrow-icon-col arrow-icon right-arrow">
                        <img className="arrow-icon-img arrow" src={right_arrow} loading="lazy" decoding="async" alt="Process Flow Arrow" aria-hidden="true" />
                    </Col>
                    <Col className="arrow-icon-col arrow-icon down-arrow">
                        <img className="arrow-icon-img arrow" src={down_arrow} loading="lazy" decoding="async" alt="Process Flow Arrow" aria-hidden="true" />
                    </Col>
                    <Col className="icon-step-col icon-step">
                        <div className='step-img-container'>
                            <img className="step-icon computer-vision-icon" src={computer_vision} loading="lazy" decoding="async" alt="Computer Vision Technology" />
                        </div>
                        <p className='icon-step-desc computer-text'>Computer Vision</p>
                    </Col>
                    <Col className="arrow-icon-col arrow-icon right-arrow">
                        <div className='step-img-container'>
                            <img className="arrow-icon-img arrow" src={right_arrow} loading="lazy" decoding="async" alt="Process Flow Arrow" aria-hidden="true" />
                        </div>
                    </Col>
                    <Col className="arrow-icon-col arrow-icon down-arrow">
                        <img className="arrow-icon-img arrow" src={down_arrow} loading="lazy" decoding="async" alt="Process Flow Arrow" aria-hidden="true" />
                    </Col>
                    <Col className="icon-step-col icon-step">
                        <div className='step-img-container'>
                            <img className="step-icon ml-model-icon" src={ml_models} loading="lazy" decoding="async" alt="Machine Learning Models" />
                        </div>
                        <p className='icon-step-desc ml-text'>ML Models</p>
                    </Col>
                    <Col className="arrow-icon-col arrow-icon right-arrow">
                        <img className="arrow-icon-img arrow" src={right_arrow} loading="lazy" decoding="async" alt="Process Flow Arrow" aria-hidden="true" />
                    </Col>
                    <Col className="arrow-icon-col arrow-icon down-arrow">
                        <img className="arrow-icon-img arrow" src={down_arrow} loading="lazy" decoding="async" alt="Process Flow Arrow" aria-hidden="true" />
                    </Col>
                    <Col className="icon-step-col icon-step">
                        <div className='step-img-container'>
                            <img className="step-icon raw-data-icon" src={raw_data} loading="lazy" decoding="async" alt="Raw Esports Data and Analytics Output" />
                        </div>
                        <p className='icon-step-desc raw-text'>Raw Data & Analytics</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HowItWorks;
