import pre_game_odds from "../../../assets/images/our_solutions/pre_game_odds.png";
import pick_em from "../../../assets/images/our_solutions/pick_em_style_games.png";
import white_label from "../../../assets/images/our_solutions/white_label_solution.png";
import micro_market from "../../../assets/images/our_solutions/in_game_micromarkets.png";
import commentator_dashboard from "../../../assets/images/our_solutions/commentator_dashboards.png";
import pregame from "../../../assets/images/our_solutions/pre_game_and_in_game_predictor_games.png";
import predictor_questions from "../../../assets/images/our_solutions/pre_game_and_in_game_predictor_questions.png";
import embeddable_widgets from "../../../assets/images/our_solutions/embeddable_widgets.png";
import pregame_playerprops from "../../../assets/images/our_solutions/pre_game_player_props.png";
import ingame_playerprops from "../../../assets/images/our_solutions/in_game_player_props.png";
import raw_data from "../../../assets/images/our_solutions/raw_data_for_daily_fantasy_sports.png";

export default {
    "Bookmaker": {
        images: [
            [pre_game_odds, "Complete pre-game odds solutions", "Pre-game Odds"],
            [micro_market, "In-game micro-markets for dynamic betting", "In-game Micro-markets"],
            [pick_em, "Pick-em style engagement games", "Pick-em Games"],
            [white_label, "White label platform integration", "White Label Solution"]
        ]
    },
    "Tournament Organizer": {
        images: [
            [commentator_dashboard, "Commentator dashboards for enhanced broadcast", "Commentator Dashboards"],
            [white_label, "White label platform for tournaments", "White Label Solution"],
            [pregame, "Pre-game and in-game predictor games", "Predictor Games"]
        ]
    },
    "Media Fan Engagement": {
        images: [
            [predictor_questions, "Pre-game and in-game predictor questions for fan interaction", "Predictor Questions"],
            [embeddable_widgets, "Embeddable widgets for content integration", "Embeddable Widgets"]
        ]
    },
    "Daily Fantasy Sports": {
        images: [
            [pregame_playerprops, "Pre-game player props data feeds", "Pre-game Player Props"],
            [ingame_playerprops, "In-game player props data feeds", "In-game Player Props"],
            [raw_data, "Raw data feeds for daily fantasy sports platforms", "Raw Data Feeds"]
        ]
    }
};
