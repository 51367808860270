import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Grid, _ } from "gridjs-react";
import Layout from "../components/Layout.js";
import './PeelBlue/css/PBDashboard.css'
import jsSHA from "jssha";

const game_types = ["CSGO", "Valorant", "Cricket", "Kabaddi"];
const game_types_map = { "LOL": "lol", "CSGO": "csgo", "DOTA2": "dota2", "Valorant": "valorant", "Rocket League": "rocketleague", "Call of Duty": "cod", "Cricket": "cricket", "Kabaddi": "kabaddi" };
const columns = [{ name: "Match ID", id: "gameid" }, "Date", "Time", "League", { name: "Team 1 Name", id: "team1_name" }, { name: "Team 2 Name", id: "team2_name" }, { name: "Hold Rate", id: "hold_rate" }, { name: "Action", id: "button" }];

function PBDashboard() {
    const navigate = useNavigate(); // Use React Router's navigate hook
    const [gameType, setGameType] = useState('');
    const [matchType, setMatchType] = useState('upcoming');
    const [matches, setMatches] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [authMessage, setAuthMessage] = useState('Please enter password...');
    const [password, setPassword] = useState('');
    const [lastDate, setLastDate] = useState('');
    const [currPage, setCurrPage] = useState(0);
    const isInitialRender = useRef(true);

    // Direct navigation using React Router
    const handleNavigation = (path) => {
        console.log("Navigating to:", path); // Debug log
        navigate(path);
    }

    useEffect(() => {
        const auth = localStorage.getItem('pb-auth');
        if (auth) {
            setAuthenticated(true)
        }
    }, [])

    useEffect(() => {
        const handleNextPage = () => {
            setTimeout(() => {
                const currentPage = document.querySelector('.gridjs-currentPage');
                const currentPageNum = parseInt(currentPage.textContent);

                setCurrPage(currentPageNum - 1)
                if (matches.length < (currentPageNum) * 30) {
                    fetch(`https://4d45dcoy45.execute-api.us-east-1.amazonaws.com/prod?game_type=${game_types_map[gameType]}&date=${lastDate}&match_type=${matchType}`, {
                        method: 'GET',
                        headers: {
                            "x-api-key": process.env.REACT_APP_RIMBLE3_API_KEY,
                        }
                    })
                        .then((res) => (res.json()))
                        .then((json) => {
                            const formattedData = json.map((match_data) => {
                                return {
                                    gameid: match_data.gameid,
                                    date: match_data.date,
                                    time: match_data.time,
                                    league: match_data.league,
                                    team1_name: match_data.team1_name,
                                    team2_name: match_data.team2_name,
                                    hold_rate: parseFloat(match_data.hold_rate.toFixed(3)),
                                    teams: match_data.teams,
                                    button: _(
                                        <button onClick={() => handleNavigation(`/peel-blue-match/${gameType}/${match_data.gameid}/${match_data.date}/${matchType}`)}>
                                            View Match Info
                                        </button>
                                    )
                                }
                            });
                            setMatches([...matches, ...formattedData])
                            const inputDate = new Date(lastDate);

                            const tenDaysAgo = new Date(inputDate.getTime() - 10 * 24 * 60 * 60 * 1000);

                            const formattedTenDaysAgo = tenDaysAgo.toISOString().slice(0, 10);
                            setLastDate(formattedTenDaysAgo)
                        })
                }
            }, 500)
        }

        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            document.querySelector('.gridjs-pages')?.addEventListener('click', handleNextPage);
        }

        return () => {
            document.querySelector('.gridjs-pages')?.removeEventListener('click', handleNextPage);
        };
    }, [currPage, lastDate, matches, gameType, matchType])

    const handleClick = (game_type) => {
        setMatches([])
        setGameType(game_type);
        fetch('https://4d45dcoy45.execute-api.us-east-1.amazonaws.com/prod?game_type=' + game_types_map[game_type] + '&match_type=' + matchType, {
            method: 'GET',
            headers: {
                "x-api-key": process.env.REACT_APP_RIMBLE3_API_KEY,
            }
        })
            .then((res) => (res.json()))
            .then((json) => {
                const formattedData = json.map((match_data) => {
                    let matchInfo = {
                        gameid: match_data.gameid,
                        date: match_data.date,
                        time: match_data.time,
                        league: match_data.league,
                        team1_name: match_data.team1_name,
                        team2_name: match_data.team2_name,
                        teams: match_data.teams,
                        hold_rate: "N/A",
                        button: _(
                            <button onClick={() => handleNavigation(`/peel-blue-match/${game_type}/${match_data.gameid}/${match_data.date}/${matchType}`)}>
                                View Match Info
                            </button>)
                    };
                    if (matchType === 'completed') {
                        matchInfo.hold_rate = parseFloat(match_data.hold_rate.toFixed(3));
                    }

                    return matchInfo;
                });
                setMatches(formattedData);
                setLastDate(formattedData[formattedData.length - 1]['date'])
                setCurrPage(0)
            })
    }

    const handleClickMatch = (match_type) => {
        setMatches([])
        setMatchType(match_type);
        fetch('https://4d45dcoy45.execute-api.us-east-1.amazonaws.com/prod?game_type=' + game_types_map[gameType] + '&match_type=' + match_type, {
            method: 'GET',
            headers: {
                "x-api-key": process.env.REACT_APP_RIMBLE3_API_KEY,
            }
        })
            .then((res) => (res.json()))
            .then((json) => {
                const formattedData = json.map((match_data) => {
                    let matchInfo = {
                        gameid: match_data.gameid,
                        date: match_data.date,
                        time: match_data.time,
                        league: match_data.league,
                        team1_name: match_data.team1_name,
                        team2_name: match_data.team2_name,
                        teams: match_data.teams,
                        hold_rate: "N/A",
                        button: _(
                            <button onClick={() => handleNavigation(`/peel-blue-match/${gameType}/${match_data.gameid}/${match_data.date}/${match_type}`)}>
                                View Match Info
                            </button>
                        )
                    };
                    if (match_type === 'completed') {
                        matchInfo.hold_rate = parseFloat(match_data.hold_rate.toFixed(3));
                    }

                    return matchInfo;
                });

                setMatches(formattedData);
                setLastDate(formattedData[formattedData.length - 1]['date'])
                setCurrPage(0)
            })
    }

    const handleInput = (event) => {
        setPassword(event.target.value)
        setAuthMessage("Please enter password...")
    }

    const handleSubmit = () => {
        var hashObj = new jsSHA("SHA-512", "TEXT", { numRounds: 1 });
        hashObj.update(password);
        var hash = hashObj.getHash("HEX");
        const dashboardPasswords = process.env.REACT_APP_DASHBOARD_PASSWORDS.split(", ");
        if (dashboardPasswords.includes(hash)) {
            setAuthMessage("Success! Logging in...");
            localStorage.setItem('pb-auth', 'true')
            setAuthenticated(true);
        } else {
            setAuthMessage("Incorrect password. Please try again...");
        }
    }

    const toggleMatchType = () => {
        // Toggle between upcoming and completed matches
        const newMatchType = matchType === 'completed' ? 'upcoming' : 'completed';
        handleClickMatch(newMatchType);
    }

    if (!authenticated) {
        return (
            <Layout>
                <h3>{authMessage}</h3>
                <input type="text" value={password} onChange={handleInput} />
                <button onClick={handleSubmit}>Submit</button>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="button-container" style={{ "top": "45px", "paddingLeft": "300px", "right": "unset", "marginRight": "unset" }}>
                <button onClick={toggleMatchType}>
                    {matchType === 'completed' ? 'View Upcoming Matches' : 'View Completed Matches'}
                </button>
            </div>
            <div className="button-container" style={{ "top": "45px" }}>
                {game_types.map((game_type, index) => (
                    <button
                        className={gameType == game_type ? 'active-button' : ''}
                        key={index}
                        onClick={() => handleClick(game_type)}
                    >
                        {game_type}
                    </button>
                ))}
            </div>
            <Grid
                className="dash-table"
                data={matches}
                columns={columns}
                sort={true}
                search={true}
                language={{
                    search: {
                        placeholder: 'Search...',
                    },
                    pagination: {
                        results: () => {

                        },
                    },
                }}
                pagination={{
                    enabled: true,
                    limit: 30,
                    resetPageOnUpdate: false,
                    page: currPage
                }}
                width="96%"
                style={{
                    container: {
                        'margin-top': 50
                    },
                    table: {
                        border: '0',
                        'font-size': '15px',
                    },
                    th: {
                        'background-color': 'white',
                        color: '#2E4993',
                        'text-align': 'center',
                        'overflow': 'visible',
                        'padding': 12,
                    },
                    td: {
                        'text-align': 'center',
                        'padding': 12,
                        'min-width': 140,
                    }
                }}
            />
        </Layout>
    )
}

export default PBDashboard;
