import React, { useState, useCallback, memo } from "react";
import OurSolutionsData from "../OurSolutions/OurSolutionsData.js";
import Card from "../../Card.js";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import "../OurSolutions/css/our-solutions.css";

function OurSolutions() {
    const [infoCard, setInfoCard] = useState("Bookmaker");

    const toggleInfoCard = useCallback((name) => {
        setInfoCard(name);
    }, []);

    return (
        <div className="our-solutions-section" id="our-solutions-section">
            <Helmet>
                <meta name="description" content="Discover Rimble's tailored esports data solutions for bookmakers, daily fantasy sports, media fan engagement, and tournament organizers. Explore our products designed to meet your specific needs in the esports industry." />
            </Helmet>
            <Container className="our-solutions-container">
                <Row className="solutions-header-container text-center">
                    <Col xs={12}>
                        <h2 className="solutions-section-header">OUR SOLUTIONS</h2>
                        <p className="solutions-section-subheader">Products Suited to Your Needs</p>
                        <p className="solutions-section-description">
                            No matter your focus in the esports industry, Rimble offers data-driven solutions tailored to your specific requirements.
                        </p>
                    </Col>
                </Row>
                <Row className="solutions-content-row">
                    <Col md={4} className="solutions-button-group">
                        {["Bookmaker", "Daily Fantasy Sports", "Media Fan Engagement", "Tournament Organizer"].map((solution, index) => (
                            <button
                                key={index}
                                className={`solutions-button ${infoCard === solution ? "active" : ""}`}
                                onClick={() => toggleInfoCard(solution)}
                                aria-pressed={infoCard === solution}
                            >
                                {solution}
                            </button>
                        ))}
                    </Col>
                    <Col md={8} className="solutions-card-container">
                        <Card data={OurSolutionsData[infoCard]} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default memo(OurSolutions);
