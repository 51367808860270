import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid, _ } from "gridjs-react";
import Layout from "../components/Layout";
import './PeelBlue/css/PBMatch.css';
import './PeelBlue/css/PBDashboard.css';

const game_types_map = { "LOL": "lol", "CSGO": "csgo", "DOTA2": "dota2", "Valorant": "valorant", "Rocket League": "rocketleague", "Call of Duty": "cod", "Cricket": "cricket", "Kabaddi": "kabaddi" };
const table_types = ["All", "Errors", "Most Active", "Most Shifted", "Top Users", "Teams"];
const general_columns = [{ name: "Match ID", id: "gameid" }, "Date", "Time", "League", { name: "Match Format", id: "match_format" }, { name: "Team 1 Name", id: "team1_name" }, { name: "Team 2 Name", id: "team2_name" }, { name: "Hold Rate", id: "hold_rate" }, { name: "Total Entry Fees", id: "total_entry_fees" }, { name: "Total Win Coin", id: "total_win_coin" }];

// Common styles for all tables
const base_table_style = {
    table: {
        border: '0',
        'font-size': '15px',
        'table-layout': 'fixed', // Fixed layout for better column control
    },
    th: {
        'background-color': 'white',
        color: '#2E4993',
        'text-align': 'center',
        'padding': 12,
    },
    td: {
        'text-align': 'center',
        'padding': 12,
    },
    container: {
        'margin-bottom': 30,
        'overflow-x': 'auto', // Prevent horizontal scroll
        'max-width': '96%'    // Keep tables within viewport
    }
};

// Table styles with specific adjustments
const table_style = {
    ...base_table_style,
    container: {
        ...base_table_style.container,
        'margin-top': 20,
    },
    th: {
        ...base_table_style.th,
        'min-width': 100,
        'max-width': 120,
    },
    td: {
        ...base_table_style.td,
        'min-width': 100,
        'max-width': 120,
    }
};

const table_option_style = {
    ...base_table_style,
    th: {
        ...base_table_style.th,
        'overflow': 'visible',
    },
    td: {
        ...base_table_style.td,
        'max-width': 180,
    }
};

const team_table_style = {
    ...base_table_style,
    th: {
        ...base_table_style.th,
        'overflow': 'visible',
    },
    td: {
        ...base_table_style.td,
        'overflow': 'visible',
    }
};

// Special style for team tables with column width control
const teams_columns = [
    {
        name: "Player",
        id: "player",
        width: '15%' // Player name column takes less space
    },
    {
        name: "Table",
        id: "table",
        width: '85%' // Stats table gets more space
    }
];

const player_table_style = {
    ...base_table_style,
    container: {
        ...base_table_style.container,
        'max-width': '100%', // Nested tables need full width
    },
    th: {
        ...base_table_style.th,
        'overflow': 'visible',
        'white-space': 'nowrap',
        'min-width': 80, // Smaller min-width for stat columns
    },
    td: {
        ...base_table_style.td,
        'white-space': 'nowrap',
        'min-width': 80, // Smaller min-width for stat columns
    }
};

function PBMatch() {
    const navigate = useNavigate(); // Using React Router's navigate hook
    const { game_type, matchid, date, match_type } = useParams();
    const [match, setMatch] = useState({});
    const [tableType, setTableType] = useState('All');
    const [tables, setTables] = useState({
        'Most Active': { data: [], columns: [], style: {} },
        'Most Shifted': { data: [], columns: [], style: {} },
        'Teams- Team 1': { data: [], columns: [], style: {} },
        'Teams- Team 2': { data: [], columns: [], style: {} }
    });

    // Direct navigation using React Router
    const handleNavigation = (path) => {
        navigate(path);
    };

    // Helper function to round numbers
    const roundNum = (num, decimal = 2) => {
        if (typeof num === "number") {
            return parseFloat(num.toFixed(decimal));
        }
        return num;
    };

    // Get appropriate API key based on game type
    const getAPIKey = () => {
        if (game_types_map[game_type] === "cricket" || game_types_map[game_type] === "kabaddi") {
            return process.env.REACT_APP_RIMBLE3_API_KEY;
        } else {
            return process.env.REACT_APP_RIMBLE_API_KEY;
        }
    };

    // Calculate days difference between dates
    const getDaysDiff = (date) => {
        const match_date = new Date(date);
        const current_date = new Date();
        const days_diff = (Math.abs(current_date - match_date)) / (1000 * 60 * 60 * 24);
        return Math.ceil(days_diff);
    };

    // Set match data with additional info from API
    const setMatchData = (match_data) => {
        const ndays = getDaysDiff(match_data["date"]);
        fetch(`https://rimbleanalytics.com/predictions/${game_types_map[game_type]}/${match_type}-matches?matchid=${matchid}&ndays=${ndays}`, {
            method: 'GET',
            headers: {
                "x-api-key": getAPIKey()
            }
        })
            .then((res) => res.json())
            .then((json) => {
                let match_format = "Match Completed";
                if (json.length > 0 && "match_format" in json[0]) {
                    const match_format_data = json[0]["match_format"];
                    if ("count" in match_format_data) {
                        match_format = match_format_data["type"] + ' ' + match_format_data["count"];
                    } else {
                        match_format = match_format_data["type"];
                    }
                }
                setMatch({
                    ...match_data,
                    match_format: match_format
                });
            })
            .catch(error => {
                console.error("Error fetching match format:", error);
                setMatch({
                    ...match_data,
                    match_format: "Unknown"
                });
            });
    };

    // Create Errors Table
    const createErrorsTable = (match_data) => {
        const { mean_error, median_error } = match_data;
        var error_columns = new Set(['Type']);
        const mean_error_data = { 'type': 'Mean Errors' };
        const median_error_data = { 'type': 'Median Errors' };

        for (const stat in mean_error) {
            error_columns.add(stat);
            mean_error_data[stat.toLowerCase()] = roundNum(mean_error[stat]);
        }

        for (const stat in median_error) {
            error_columns.add(stat);
            median_error_data[stat.toLowerCase()] = roundNum(median_error[stat]);
        }

        error_columns = Array.from(error_columns);
        const error_data = [mean_error_data, median_error_data];

        return {
            data: error_data,
            columns: error_columns,
            style: table_option_style
        };
    };

    // Create Most Active Table
    const createMostActiveTable = (match_data) => {
        const { most_active_lines } = match_data;
        const most_active_data = [];
        const active_columns = [
            "Player",
            { name: "Stat Type", id: "stat_type" },
            { name: "Entry Fees", id: "entry_fees" },
            { name: "Number of Entries", id: "num_entries" },
            { name: "Opening Prediction", id: "opening_prediction" },
            { name: "Closing Prediction", id: "prediction_value" },
            { name: "Actual", id: "actual_value" }
        ];

        for (const active_entry of most_active_lines) {
            if (active_entry.line_history && active_entry.line_history.length) {
                most_active_data.push({
                    ...active_entry,
                    opening_prediction: active_entry.line_history[0]["Old"],
                });
            } else {
                most_active_data.push({
                    ...active_entry,
                    opening_prediction: active_entry.prediction_value
                });
            }
        }

        return {
            data: most_active_data,
            columns: active_columns,
            style: table_option_style
        };
    };

    // Create Most Shifted Table
    const createMostShiftedTable = (match_data) => {
        const { most_shifted_lines } = match_data;
        const most_shifted_data = [];
        const shifted_columns = [
            "Player",
            { name: "Stat Type", id: "stat_type" },
            { name: "Number of Shifts", id: "num_shifts" },
            { name: "Opening Prediction", id: "opening_prediction" },
            { name: "Closing Prediction", id: "prediction_value" },
            { name: "Actual", id: "actual_value" }
        ];

        for (const shift_entry of most_shifted_lines) {
            if (shift_entry.line_history && shift_entry.line_history.length) {
                most_shifted_data.push({
                    ...shift_entry,
                    opening_prediction: shift_entry.line_history[0]["Old"],
                });
            } else {
                most_shifted_data.push({
                    ...shift_entry,
                    opening_prediction: shift_entry.prediction_value
                });
            }
        }

        return {
            data: most_shifted_data,
            columns: shifted_columns,
            style: table_option_style
        };
    };

    // Create Top Users Table
    const createTopUsersTable = (match_data) => {
        const { top_users } = match_data;
        const top_users_columns = [
            { name: "User ID", id: "user_id" },
            { name: "Phone Number", id: "phone_number" },
            { name: "Entry Fees", id: "entry_fees" },
            { name: "Number of Entries", id: "num_entries" },
            { name: "Amount Won", id: "amount_won" },
            { name: "Net Loss", id: "net_loss" }
        ];

        return {
            data: top_users,
            columns: top_users_columns,
            style: table_option_style
        };
    };

    // Create Teams Table
    const createTeamsTable = (team_data) => {
        const team_players = team_data.players;
        const team_table_data = team_players.map(player_data => createPlayerEntry(player_data));

        return {
            data: team_table_data,
            columns: teams_columns, // Using predefined columns with width settings
            style: team_table_style
        };
    };

    // Create Player Entry for Team Tables
    const createPlayerEntry = (player_data) => {
        var player_entry = { "player": player_data.name };
        var player_table_data = [];
        var player_table_columns = new Set(["Type"]);
        const line_shift_history = player_data.line_history;

        for (const data_type of Object.keys(player_data)) {
            if (data_type !== "name" && data_type !== "stat_values" && data_type !== "line_history" && data_type !== "prediction_value") {
                var player_data_entry = { "type": data_type };

                for (const stat of Object.keys(player_data[data_type])) {
                    player_table_columns.add(stat);
                    player_data_entry[stat.toLowerCase()] = roundNum(player_data[data_type][stat]);
                }

                player_table_data.push(player_data_entry);
            }
        }

        player_table_columns = Array.from(player_table_columns);

        var opening_prediction_data = { "type": "Opening Prediction" };
        var closing_prediction_data = { "type": "Closing Prediction" };

        for (const stat of player_table_columns.slice(1)) {
            if (!line_shift_history || !(Object.keys(line_shift_history).includes(stat))) {
                opening_prediction_data[stat.toLowerCase()] = roundNum(player_data.prediction[stat]);
            } else {
                opening_prediction_data[stat.toLowerCase()] = roundNum(line_shift_history[stat][0]["Old"]);
            }
            closing_prediction_data[stat.toLowerCase()] = roundNum(player_data.prediction[stat]);
        }

        player_table_data.push(opening_prediction_data);
        player_table_data.push(closing_prediction_data);

        // Format the player stat columns with auto width distribution
        const formatted_player_columns = player_table_columns.map(col => {
            if (col === "Type") {
                return {
                    name: col,
                    width: '120px', // Fixed width for type column
                };
            } else {
                return {
                    name: col,
                    // Each stat column gets equal share of remaining space
                    width: `${80 / (player_table_columns.length - 1)}%`
                };
            }
        });

        player_entry["table"] = _(
            <div className="player-table-wrapper">
                <Grid
                    className="dash-table player-table"
                    data={player_table_data}
                    columns={formatted_player_columns}
                    style={player_table_style}
                    width="100%"
                />
            </div>
        );

        return player_entry;
    };

    // Handle button click to change table type
    const handleClick = (table_type) => {
        setTableType(table_type);
    };

    // Fetch match data on component mount
    useEffect(() => {
        fetch(`https://4d45dcoy45.execute-api.us-east-1.amazonaws.com/prod?game_type=${game_types_map[game_type]}&matchid=${matchid}&date=${date}&match_type=${match_type}`, {
            method: 'GET',
            headers: {
                "x-api-key": process.env.REACT_APP_RIMBLE3_API_KEY,
            }
        })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    if ("hold_rate" in json[0]) {
                        json[0]["hold_rate"] = roundNum(json[0]["hold_rate"], 3);
                        setMatchData(json[0]);
                        setTables({
                            "Errors": createErrorsTable(json[0]),
                            "Most Active": createMostActiveTable(json[0]),
                            "Most Shifted": createMostShiftedTable(json[0]),
                            "Top Users": createTopUsersTable(json[0]),
                            "Teams- Team 1": createTeamsTable(json[0].teams[0]),
                            "Teams- Team 2": createTeamsTable(json[0].teams[1])
                        });
                    } else {
                        json[0]["hold_rate"] = "N/A";
                        setMatchData(json[0]);
                        setTables({
                            "Most Active": createMostActiveTable(json[0]),
                            "Most Shifted": createMostShiftedTable(json[0]),
                            "Teams- Team 1": createTeamsTable(json[0].teams[0]),
                            "Teams- Team 2": createTeamsTable(json[0].teams[1])
                        });
                    }
                } else {
                    console.error("No match data found");
                }
            })
            .catch(error => {
                console.error("Error fetching match data:", error);
            });
    }, [game_type, matchid, date, match_type]);

    return (
        <Layout>
            {/* Table type selector */}
            <div className="button-container" style={{ "width": "37%" }}>
                {table_types.map((table_type, index) => (
                    <button
                        className={tableType === table_type ? 'active-button' : ''}
                        key={index}
                        onClick={() => handleClick(table_type)}
                    >
                        {table_type}
                    </button>
                ))}
            </div>

            {/* Back button */}
            <button onClick={() => handleNavigation(`/peel-blue-dashboard`)}>
                <p className="back-button">&larr; Main</p>
            </button>

            {/* Main match info table */}
            <div className="table-container">
                <Grid
                    className="dash-table"
                    data={[match]}
                    columns={general_columns}
                    width="96%"
                    style={table_style}
                />
            </div>

            {/* Display tables based on selected tab */}
            <div className="tables-wrapper">
                {tableType === 'All' ?
                    // Show all tables
                    Object.keys(tables).map((table_name) => {
                        const { data, columns, style } = tables[table_name];
                        var title = table_name;
                        if (table_name.includes("Most")) {
                            title += ' Lines';
                        }
                        return (
                            <div key={table_name} className="table-section">
                                <p className="table-title">{title}</p>
                                <div className="table-container">
                                    <Grid
                                        className="dash-table"
                                        data={data}
                                        columns={table_name.includes("Teams") ? teams_columns : columns}
                                        style={style}
                                        width="96%"
                                        sort={title.includes("Teams") ? false : true}
                                    />
                                </div>
                            </div>
                        );
                    })
                    : tableType === 'Teams' ?
                        // Show team tables
                        <div>
                            <p className="table-title">Teams- Team 1</p>
                            <div className="table-container team-table-container">
                                <Grid
                                    className="dash-table"
                                    data={tables["Teams- Team 1"].data}
                                    columns={teams_columns}
                                    style={tables["Teams- Team 1"].style}
                                    width="96%"
                                />
                            </div>
                            <p className="table-title">Teams- Team 2</p>
                            <div className="table-container team-table-container">
                                <Grid
                                    className="dash-table"
                                    data={tables["Teams- Team 2"].data}
                                    columns={teams_columns}
                                    style={tables["Teams- Team 2"].style}
                                    width="96%"
                                />
                            </div>
                        </div>
                        :
                        // Show specific table
                        <div>
                            <p className="table-title">{tableType.includes("Most") ? tableType + " Lines" : tableType}</p>
                            <div className="table-container">
                                <Grid
                                    className="dash-table"
                                    data={tables[tableType]?.data || []}
                                    columns={tables[tableType]?.columns || []}
                                    style={tables[tableType]?.style || table_option_style}
                                    width="96%"
                                />
                            </div>
                        </div>
                }
            </div>
        </Layout>
    );
}

export default PBMatch;
