import "../Footer/css/footer.css";
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigate as navigateAction } from '../../store/navigationSlice';
import { HashLink } from "react-router-hash-link";

function Footer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleNavigation = (path) => {
        dispatch(navigateAction(path));
        navigate(path);
    };

    const handleHomePage = (section) => {
        handleNavigation('/');
        setTimeout(() => {
            const contactSection = document.getElementById(section);
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <footer className="footer-hold" id="footer">
            <div className="footer-container">
                <div className="footer-links-container">

                    <div className="footer-links">
                        <div className="footer-header-links">
                            <h1 className="footer-rimble-header">Rimble</h1>
                            <p className="address-footer">
                                San Francisco HQ
                                <br />
                                149 New Montgomery St, 4th Floor
                                <br />
                                San Francisco, CA 94105
                            </p>
                        </div>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Product</strong>
                        <Nav.Link className="footer-link" onClick={() => handleHomePage('how-it-works-section')}>How It Works</Nav.Link>
                        <a className="footer-link" href="https://documenter.getpostman.com/view/16449503/Tzm8FvFw" target="_blank" rel="noopener noreferrer">
                            API Documentation
                        </a>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Company</strong>
                        <Nav.Link className="footer-link" onClick={() => handleNavigation('/about')}>Our Mission</Nav.Link>
                        <Nav.Link className="footer-link" onClick={() => handleHomePage('why-rimble-section')}>Why Rimble</Nav.Link>
                        <Nav.Link className="footer-link" onClick={() => handleNavigation('/media')}>Media</Nav.Link>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Resources</strong>
                        <Nav.Link className="footer-link" onClick={() => handleNavigation('/privacypolicy')}>Privacy Policy</Nav.Link>
                        <Nav.Link className="footer-link" onClick={() => handleNavigation('/terms')}>Terms</Nav.Link>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Contact Us</strong>
                        <Nav.Link className="footer-link" onClick={() => handleHomePage('contact-us')}>Get Started</Nav.Link>

                        <a className="footer-link" href="mailto:support@rimble.io">
                            support@rimble.io
                        </a>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-text">&copy; Copyright 2024. All rights reserved</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
