import Layout from "../components/Layout.js"
import OurVision from "../components/About/our-vision.js";
import OurTeam from "../components/About/our-team.js";
import { Helmet } from 'react-helmet';
import "../components/About/css/about.css"

function About() {
    return (
        <Layout>
            <Helmet>
                <title>About Rimble - AI-Powered Esports Analytics & Betting Solutions</title>
                <meta name="description" content="Learn about Rimble, the leading provider of AI-driven esports analytics and betting solutions. Discover our vision, mission, and the team behind our cutting-edge esports data platform." />
                <meta name="keywords" content="about Rimble, Rimble company, esports analytics company, esports betting solutions, esports data platform, our team, company vision, company mission, AI esports, machine learning esports, data analytics esports" />
            </Helmet>
            <div className="about-page-content">
                <h1 className="about-page-header">
                    About Rimble: Revolutionizing Esports with AI-Powered Analytics
                </h1>
                <h2  className="about-page-intro">
                    We created Rimble to provide more accurate data to the esport's industry
                </h2>
            </div>
            <OurVision />
            <OurTeam />
        </Layout>
    )
}

export default About;
