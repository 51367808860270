import { Container, Col, Row } from "react-bootstrap";
import "../Terms/css/ourterms.css";

function OurTerms() {
    return (
        <div className="ourterms" id="ourterms">
            <Container className="our-vision-container">
                <Col className="our-vision-main" id="our-vision">
                    <Row className="about-header-container text-center">
                        <h1 className="terms-header">Terms & Conditions</h1>
                        <p className="terms-updated-date">Last updated: October 1, 2024</p>
                    </Row>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Interpretation and Definitions</h2>
                        <section className="terms-subsection">
                            <h3 className="terms-subsection-header">Interpretation</h3>
                            <p className="terms-text">
                                The words of which the initial letter is capitalized have meanings defined under the following conditions. These definitions shall have the same meaning regardless of whether they appear in the singular or plural form.
                            </p>
                        </section>

                        <section className="terms-subsection">
                            <h3 className="terms-subsection-header">Definitions</h3>
                            <p className="terms-text">For the purposes of these Terms and Conditions:</p>
                            <ul className="terms-list">
                                <li className="terms-list-item">
                                    <p>
                                        <strong>“Affiliate”</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means
                                        ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.
                                    </p>
                                </li>
                                <li className="terms-list-item">
                                    <p>
                                        <strong>“Company”</strong> (referred to as either "the Company", "We", "Us" or "Our" in these Terms and Conditions) refers to **Rimble, Inc.**, a Delaware corporation, with its principal place of business at 149 New Montgomery St, San Francisco 94105.
                                    </p>
                                </li>
                                <li className="terms-list-item">
                                    <p><strong>“Country”</strong> refers to the United States of America, specifically the State of California.</p>
                                </li>
                                <li className="terms-list-item">
                                    <p><strong>“Device”</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</p>
                                </li>
                                <li className="terms-list-item">
                                    <p><strong>“Privacy Policy”</strong> means the Company's Privacy Policy available at: <a href="https://rimble.io/privacypolicy" target="_blank" rel="noopener noreferrer">rimble.io/privacypolicy</a>.</p>
                                </li>
                                <li className="terms-list-item">
                                    <p><strong>“Service”</strong> refers to the Website.</p>
                                </li>
                                <li className="terms-list-item">
                                    <p>
                                        <strong>“Terms and Conditions (also referred as “Terms”)”</strong> mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
                                    </p>
                                </li>
                                <li className="terms-list-item">
                                    <p>
                                        <strong>“Third-party Social Media Service”</strong> means any services or content (including data, information, products, or services) provided by a
                                        third-party that may be displayed, included, or made available by the Service.
                                    </p>
                                </li>
                                <li className="terms-list-item">
                                    <p><strong>“Website”</strong> refers to the Company's website available at: <a href="https://www.rimble.io/" target="_blank" rel="noopener noreferrer">https://www.rimble.io/</a>.</p>
                                </li>
                                <li className="terms-list-item">
                                    <p><strong>“You”</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                                </li>
                            </ul>
                        </section>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Acknowledgment</h2>
                        <p className="terms-text">
                            These are the Terms and Conditions governing the use of this Service. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
                        </p>
                        <p className="terms-text">Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.</p>
                        <p className="terms-text">By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.</p>
                        <p className="terms-text">You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                        <p className="terms-text">
                            Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the
                            collection, use, and disclosure of Your personal information when You use the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully
                            before using Our Service.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Prohibited Uses</h2>
                        <p className="terms-text">When using the Service it is prohibited to:</p>
                        <ul className="terms-list">
                            <li className="terms-list-item">
                                <p>use the Service in a manner that could cause damage to Us, Our clients, or third parties;</p>
                            </li>
                            <li className="terms-list-item">
                                <p>alter the content of the Service, interfere with it in any way, endanger and/or disrupt its operation;</p>
                            </li>
                            <li className="terms-list-item">
                                <p>use programs, equipment, or other mechanisms and procedures that could adversely affect the operation of the Service;</p>
                            </li>
                            <li className="terms-list-item">
                                <p>improperly obtain personal information of other clients or information that is the subject of Our intellectual property or trade secrets; and</p>
                            </li>
                            <li className="terms-list-item">
                                <p>use unsecured e-mail or disclose Your account access password to any third party.</p>
                            </li>
                        </ul>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Termination</h2>
                        <p className="terms-text">
                            We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
                        </p>
                        <p className="terms-text">Upon termination, Your right to use the Service will cease immediately.</p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Limitation of Liability</h2>
                        <p className="terms-text">
                            Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of these Terms and Your exclusive remedy for all of the foregoing
                            shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.
                        </p>
                        <p className="terms-text">
                            To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software, or otherwise in connection with any provision of this Terms).
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                        <p className="terms-text">
                            The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company expressly disclaims all warranties, express or implied, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement.
                        </p>
                        <p className="terms-text">
                            Without limiting the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, be error-free, or be corrected.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Governing Law</h2>
                        <p className="terms-text">The laws of the State of California, excluding its conflicts of law rules, shall govern these Terms and Your use of the Service.</p> {/* Updated Governing Law to California */}
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Disputes Resolution</h2>
                        <p className="terms-text">If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Severability and Waiver</h2>
                        <section className="terms-subsection">
                            <h3 className="terms-subsection-header">Severability</h3>
                            <p className="terms-text">
                                If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible
                                under applicable law and the remaining provisions will continue in full force and effect.
                            </p>
                        </section>
                        <section className="terms-subsection">
                            <h3 className="terms-subsection-header">Waiver</h3>
                            <p className="terms-text">Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right at any time thereafter.</p>
                        </section>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Translation Interpretation</h2>
                        <p className="terms-text">These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Changes to These Terms and Conditions</h2>
                        <p className="terms-text">
                            We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, We will make reasonable efforts to provide at least 30 days' notice prior to any new
                            Terms taking effect. By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised Terms.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-section-header">Personal Data Protection</h2>
                        <p className="terms-text">Personal Data protection is governed by the Privacy Policy available at: <a href="rimble.io/privacypolicy">rimble.io/privacypolicy</a>.</p>
                    </section>
                </Col>
            </Container>
        </div>
    );
}

export default OurTerms;
