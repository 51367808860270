import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ContactUs/css/contact-us.css";

function ContactUs() {
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('zoho', 'rimble_template', e.target, 'bmbzwaO6WB7beMsjK')
            .then((result) => {
                toast.success("Email sent successfully! We'll be in touch soon");
            }, (error) => {
                toast.error("Failed to send email. Please try again.");
            });
        e.target.reset();
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
    };

    return (
        <div className="contact-us" id="contact-us">
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} closeOnClick />
            <div className="contact-us-container">
                <div className="contact-header">
                    <h2>CONTACT US</h2>
                    <h3>Want a quote or additional information? Fill out the form to contact our team!</h3>
                    <a href="mailto:support@rimble.io">support@rimble.io</a>
                </div>
                <form className="contact-form" onSubmit={sendEmail}>
                    <input
                        type="text"
                        name="firstname"
                        placeholder="First Name*"
                        className="form-field"
                        value={firstname}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        name="lastname"
                        placeholder="Last Name*"
                        className="form-field"
                        value={lastname}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email*"
                        className="form-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        name="companyname"
                        placeholder="Company Name"
                        className="form-field"
                    />
                    <input
                        type="text"
                        name="website"
                        placeholder="Website"
                        className="form-field"
                    />
                    <textarea
                        name="message"
                        placeholder="Message*"
                        rows={6}
                        className="form-field-message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                    <button type="submit" className="submit-button" disabled={!firstname || !lastname || !email || !message}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ContactUs;
