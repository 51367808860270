import { Container, Col, Row } from "react-bootstrap";
import "../About/css/our-vision.css";

function OurVision() {
    return (
        <div className="our-vision-section" id="our-vision-section">
            <Container className="our-vision-container">
                <Col className="our-vision-content" id="our-vision-content">
                    <Row className="vision-header-container text-center">
                        <h2 className="vision-header glitch-effect">Our Vision</h2>
                        <p className="vision-description">
                            Founded in Berkeley, California in 2019, Rimble's vision addresses the critical need for precise esports betting analytics.
                            Recognizing the limitations of traditional sports models in the esports realm, we pioneered a new approach.
                            Rimble utilizes advanced machine learning algorithms, real-time computer vision, and comprehensive game data streams to deliver unparalleled esports predictions and odds generation.
                            Our mission is to transform esports fan engagement, empowering businesses to build richer, data-driven experiences for fans and bettors alike through our cutting-edge esports analytics platform.
                        </p>
                    </Row>
                </Col>
            </Container>
        </div>
    );
}

export default OurVision;
