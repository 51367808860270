import { Container, Nav, Row, Col } from "react-bootstrap"; 
import "../MediaReleases/css/mediareleases.css";
import article_zero_img from "../../assets/images/media/article_zero.png";
import article_one_img from "../../assets/images/media/article_one.jpg";
import article_two_img from "../../assets/images/media/article_two.jpg";
import article_three_img from "../../assets/images/media/article_three.png";
import article_four_img from "../../assets/images/media/article_four.webp";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigate as navigateAction } from '../../store/navigationSlice';

function MediaReleases() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        dispatch(navigateAction(path));
        navigate(path);
    };

    return (
        <div className="media-releases-page" id="media-releases-page">
            <Container className="media-releases-container">
                <div className="media-releases-content" id="media-releases-content">
                    <div className="media-header-container text-center">
                        <h1 className="media-header">Newsroom</h1>
                    </div>

                    <section className="press-releases-section">
                        <h2 className="press-releases-header">Press Releases</h2>
                        <br />
                        <Row className="press-release-grid">
                            <Col md={6} lg={4} className="release-column">
                                <article className="release">
                                    <a className="press-release-link" href="https://www.egr.global/intel/news/this-week-in-b2b-news-14-february/">
                                        <div className="article-img-container">
                                            <img
                                                src={article_zero_img}
                                                className="press-release-img"
                                                alt="Rimble esports bet builder featured in EGR Global news - Press Release"
                                            />
                                        </div>
                                        <p className="press-release-title">Rimble transforms esports landscape with bet builder</p> {/* More specific class for title */}
                                        <p className="press-release-excerpt">Read about Rimble's innovative bet builder solution for esports in EGR Global. This press release highlights our impact on the esports betting industry.</p>
                                    </a>
                                </article>
                            </Col>
                            <Col md={6} lg={4} className="release-column">
                                <article className="release">
                                    <a className="press-release-link" href="https://www.intergameonline.com/igaming/news/rimble-esports-cricket-betting-offerings-integrate-with-abelson">
                                        <div className="article-img-container">
                                            <img
                                                src={article_one_img}
                                                className="press-release-img"
                                                alt="Rimble esports cricket betting integration with Abelson Info - News from InterGame Online"
                                            />
                                        </div>
                                        <p className="press-release-title">Rimble esports, cricket betting offerings integrate with Abelson</p>
                                        <p className="press-release-excerpt">InterGame Online reports on Rimble's partnership with Abelson Info, enhancing cricket betting offerings within the esports sector.</p>
                                    </a>
                                </article>
                            </Col>
                            <Col md={6} lg={4} className="release-column">
                                <article className="release">
                                    <a className="press-release-link" href="https://sbcnews.co.uk/europe/2024/09/20/rimbleabelsoninfointegrate/">
                                        <div className="article-img-container">
                                            <img
                                                src={article_two_img}
                                                className="press-release-img"
                                                alt="Rimble and Abelson Info integrate esports and cricket betting markets - SBC News"
                                            />
                                        </div>
                                        <p className="press-release-title">Rimble and Abelson Info integrate esports and cricket betting</p>
                                        <p className="press-release-excerpt">SBC News covers the integration between Rimble and Abelson Info, bringing advanced betting solutions to esports and cricket.</p>
                                    </a>
                                </article>
                            </Col>
                            <Col md={6} lg={4} className="release-column">
                                <article className="release">
                                    <a className="press-release-link" href="https://g3newswire.com/rimble-partners-with-abelson-to-bring-enhanced-player-props-to-sportsbooks/">
                                        <div className="article-img-container">
                                            <img
                                                src={article_three_img}
                                                className="press-release-img"
                                                alt="Rimble partners with Abelson for enhanced player props in sportsbooks - G3 Newswire"
                                            />
                                        </div>
                                        <p className="press-release-title">Rimble partners with Abelson to bring enhanced player props to sportsbooks</p>
                                        <p className="press-release-excerpt">G3 Newswire announces Rimble's partnership with Abelson to deliver enhanced player props, improving sportsbook offerings.</p>
                                    </a>
                                </article>
                            </Col>
                            <Col md={6} lg={4} className="release-column">
                                <article className="release">
                                    <a className="press-release-link" href="https://sigma.world/news/abelson-info-and-rimble-partner-to-elevate-esports-betting-markets/">
                                        <div className="article-img-container">
                                            <img
                                                src={article_four_img}
                                                className="press-release-img"
                                                alt="Abelson Info and Rimble partner to elevate esports betting markets - SiGMA News"
                                            />
                                        </div>
                                        <p className="press-release-title">Abelson Info and Rimble partner to elevate esports betting markets</p>
                                        <p className="press-release-excerpt">SiGMA World reports on the partnership between Abelson Info and Rimble to enhance the esports betting market experience.</p>
                                    </a>
                                </article>
                            </Col>
                        </Row>
                    </section>

                    <section className="articles-section">
                        <h2 className="articles-header">Articles</h2>
                        <ul className="articles-list">
                            <li className="article-list-item">
                                <Nav.Link className="navbar-link article-nav-link" onClick={() => handleNavigation('/analyticsimportance')}>The Power of Analytics in Esports: A Game Changer</Nav.Link> {/* More specific class for article nav link */}
                                <p className="article-excerpt">Explore how advanced data analytics are revolutionizing the esports industry, offering teams and platforms a significant competitive advantage and deeper insights.</p>
                            </li>
                            <li className="article-list-item">
                                <Nav.Link className="navbar-link article-nav-link" onClick={() => handleNavigation('/esportsbettingexplained')}>Esports Betting Explained: A Look at the Trends & Challenges</Nav.Link>
                                <p className="article-excerpt">Understand the growing global trends and inherent challenges within the rapidly evolving and dynamic world of esports betting markets.</p>
                            </li>
                            <li className="article-list-item">
                                <Nav.Link className="navbar-link article-nav-link" onClick={() => handleNavigation('/esportsrisksmechanics')}>Esports Betting: Navigating the Risks with Rimble</Nav.Link>
                                <p className="article-excerpt">Learn about the potential risks associated with esports betting and discover how Rimble's solutions help stakeholders navigate these complexities effectively and responsibly.</p>
                            </li>
                        </ul>
                    </section>

                </div>
            </Container >
        </div >
    );
}

export default MediaReleases;
