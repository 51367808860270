import React from "react";
import "../components/Home/OurSolutions/css/our-solutions.css";

const Card = ({ data }) => {
    return (
        <div className="cards">
            <div className="card-info-container">
                {data.images && data.images.map((imageData, index) => (
                    <div className="card-icon-group" key={index}>
                        <div className="image-container">
                            <img
                                src={imageData[0]}
                                className="card-image"
                                alt={imageData[1]}
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <p className="solutions-card-header">{imageData[2]}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Card;
