import "../HomeHeader/css/home-header.css";
import { Button, Container, Row, Col } from 'react-bootstrap';
import rimble_computer from "../../../assets/images/rimble-computer.png";

function HomeHeader() {
    return (
        <div className="home-header-section" id="home-header-section">
            <Container className="home-header-container">
                <Col className="home-header-text-col">
                    <h1 className="home-header-main-heading">Next Generation Esports Odds and Analytics</h1>
                    <p className="home-header-description">Sophisticated machine learning models and proprietary raw data to create infrastructure for delightful esports experiences.</p>
                    <Row className="home-header-buttons-row">
                        <Button
                            className="get-started-button"
                            href="#contact-us"
                            onClick={(event) => {
                                event.preventDefault();
                                document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
                            }}
                        >
                            Get Started
                        </Button>
                        <Button
                            className="learn-more-button"
                            href="#how-it-works"
                            onClick={(event) => {
                                event.preventDefault();
                                document.getElementById("how-it-works-section").scrollIntoView({ behavior: 'smooth' });
                            }}
                        >
                            Learn More
                        </Button>
                    </Row>
                </Col>
                <Col className="home-header-image-col">
                    <img
                        src={rimble_computer}
                        className="home-header-computer-image"
                        alt="Rimble AI-Powered Esports Analytics Platform"
                        loading="lazy"
                        decoding="async"
                    />
                </Col>
            </Container>
        </div>
    );
}

export default HomeHeader;
