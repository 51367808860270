import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid } from 'gridjs-react';
import "../components/Dashboard/css/dash-teams.css"
import Layout from "../components/Layout";
import { connect } from 'react-redux';
import { navigate as navigateAction } from '../store/navigationSlice';
import withNavigation from './withNavigation';

// CSV Utility function (No changes needed)
const convertToCSV = (gridData, columns, includeHeaders = true) => {
    if (!gridData || gridData.length === 0) {
        return "No data available\r\n";
    }

    let result = "";

    if (includeHeaders) {
        const columnHeaders = columns
            .filter(col => col && col.name)
            .map(col => col.name)
            .join(',');
        result += `${columnHeaders}\r\n`;
    }

    const csvRows = gridData.map(row => {
        return columns
            .filter(col => col && col.name)
            .map(col => {
                let cellValue = row[col.name] || '';
                cellValue = String(cellValue).replace(/"/g, '""');
                return `"${cellValue}"`;
            })
            .join(',');
    });

    result += csvRows.join('\r\n') + '\r\n';
    return result;
};

const formatTableFunction = (rawData, player, game_type_param, componentState) => {
    if (!rawData) {
        const playerName = player.username ? `${player.username} ` : `${player.name} `;
        const playerNameWithRole = player.role ? `${playerName}(${player.role})` : playerName;

        return {
            name: playerNameWithRole,
            username: player.username,
            id: player.id,
            gridData: [],
            gridColumns: [{ name: 'No Data Available', width: '100%' }],
            player: player
        };
    }

    const playerName = player.username ? `${player.username} ` : `${player.name} `;
    const playerNameWithRole = player.role ? `${playerName}(${player.role})` : playerName;

    let grid_data, grid_columns;

    const processedData = removeNARows(rawData).map(obj => {
        const result = {};
        for (const key in obj) {
            result[key.replace(/\s+/g, "_").toLowerCase()] = obj[key];
        }
        return result;
    });

    if (game_type_param === "nba" || game_type_param === "soccer") {
        grid_data = processedData;

        const uniqueKeys = new Set();
        for (const item of grid_data) {
            for (const key in item) {
                uniqueKeys.add(key.toLowerCase());
            }
        }

        const columnKeys = Array.from(uniqueKeys);
        grid_columns = [];
        if (columnKeys.includes('type')) {
            grid_columns.push({ name: 'type', width: `${Math.max('type'.length * 15, 110)}px` });
            columnKeys.filter(key => key !== 'type').sort().forEach(key => {
                grid_columns.push({ name: key, width: `${Math.max(key.length * 15, 110)}px` });
            });
        } else { // If 'type' is not in uniqueKeys (unlikely but for robustness)
            columnKeys.sort().forEach(key => {
                grid_columns.push({ name: key, width: `${Math.max(key.length * 15, 110)}px` });
            });
        }


    } else {
        grid_data = processedData;

        const sorted_columns = [...componentState.columns].sort();

        grid_columns = [];
        grid_columns.push({ name: 'type', width: `${Math.max('type'.length * 15, 110)}px` });
        sorted_columns
            .filter(col => col !== 'type' && col !== 'role')
            .forEach(item => {
                grid_columns.push({
                    name: item.toLowerCase(),
                    width: `${Math.max(item.length * 15, 110)}px`
                });
            });
    }

    return {
        name: playerNameWithRole,
        username: player.username,
        id: player.id,
        gridData: grid_data,
        gridColumns: grid_columns,
        player: player
    };
};

function removeNARows(array) {
    if (!Array.isArray(array)) return [];

    // Use more efficient filtering
    return array.filter(obj => {
        // Early return optimization
        for (const key in obj) {
            if (key !== 'type' && obj[key] !== 'n/a' && obj[key] !== '0.00') {
                return true;
            }
        }
        return false;
    });
}


class Teams extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            game_type: "",
            timeframe: "",
            league: "",
            matchid: "",
            team_1_name: "",
            team_2_name: "",
            matchup: [],
            team_1_stats: {}, // Changed to object to store player stats individually
            team_1_win_prob: 0,
            team_2_stats: {}, // Changed to object to store player stats individually
            team_2_win_prob: 0,
            odds: [],
            complete_odds: "",
            team_1_stats_loading: false, // Overall loading removed, player specific loading will be used
            team_2_stats_loading: false, // Overall loading removed, player specific loading will be used
            odds_loading: true,
            matchup_loading: true,
            errorMatchup: false,
            apiError: false,
            columns: new Set(["type"]),
            dataLoaded: false,
        };
    }

    handleGoBack = () => {
        window.history.back();
    }

    handleNavigation(path) {
        this.props.navigateAction(path);
    }

    getAPIKey(game) {
        return (game == "cricket" || game == "kabaddi") ? process.env.REACT_APP_RIMBLE3_API_KEY : process.env.REACT_APP_RIMBLE_API_KEY;
    }

    getLines(predictions) {
        if (!predictions || !predictions.teams || !Array.isArray(predictions.teams) || predictions.teams.length < 2) {
            this.setState({ odds_loading: false, errorOdds: true, errorMessage: "Invalid predictions data received." });
            return;
        }
        this.setState({ team_1_win_prob: predictions.teams[0]?.win_probability?.toFixed(3) || 'n/a', team_2_win_prob: predictions.teams[1]?.win_probability?.toFixed(3) || 'n/a' }, () => {
            this.getMatchOdds(predictions);
        });
    }

    getMatchOdds(predictions) {
        // Parameter Sanitization
        const date_encoded = encodeURIComponent(predictions.date);
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const matchid_encoded = encodeURIComponent(this.props.params.matchid);

        this.setState({ odds_loading: true, errorOdds: false });

        fetch(
            "https://7cve45w77f.execute-api.us-east-1.amazonaws.com/prod/?date=" + date_encoded + "&game=" + game_type_encoded + "&gameid=" + matchid_encoded, {
            method: "GET",
            headers: {
                "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
            }
        })
        .then(res => res.json())
        .then((json) => {
            const body = JSON.parse(json.body);
            this.setState({
              odds_complete: body})
            if ("no odds or projections for given gameid" in body) {
                this.setState({
                    odds: [{
                        'team_1_name': predictions.team_1_name,
                        'team_2_name': predictions.team_2_name,
                        'team_1_rimble_win_prob': this.state.team_1_win_prob,
                        'team_2_rimble_win_prob': this.state.team_2_win_prob,
                        'team_1_bookmaker_win_prob': 'n/a',
                        'team_2_bookmaker_win_prob': 'n/a',
                        'bookmaker': 'n/a'
                    }],
                    odds_loading: false, errorOdds: false
                }, () => {
                    this.getPlayersAndStats(predictions);
                });

            } else {
                var odds = []
                for (const [key, value] of Object.entries(body.odds || {})) {
                    odds.push(...value);
                }
                if (odds.length == 0) {
                    this.setState({
                        odds: [{
                            'team_1_name': predictions.team_1_name,
                            'team_2_name': predictions.team_2_name,
                            'team_1_rimble_win_prob': this.state.team_1_win_prob,
                            'team_2_rimble_win_prob': this.state.team_2_win_prob,
                            'team_1_bookmaker_win_prob': 'n/a',
                            'team_2_bookmaker_win_prob': 'n/a',
                            'bookmaker': 'n/a'
                        }],
                        odds_loading: false, errorOdds: false
                    }, () => {
                        this.getPlayersAndStats(predictions);
                    });
                } else {
                    for (let index in odds) {
                        if (odds[index]?.team_1_win_prob == "NA" || odds[index]?.team_2_win_prob == "NA") {
                            odds.splice(index, 1);
                        }
                        if (index < odds.length) {
                            odds[index]["team_1_name"] = this.props.params.team_1
                            odds[index]["team_2_name"] = this.props.params.team_2
                            odds[index]["team_1_bookmaker_win_prob"] = (odds[index].team_1_win_prob)?.toFixed(3) || 'n/a'
                            odds[index]["team_2_bookmaker_prob"] = (odds[index].team_2_win_prob)?.toFixed(3) || 'n/a'
                            odds[index]["team_1_rimble_win_prob"] = (this.state.team_1_win_prob)
                            odds[index]["team_2_rimble_win_prob"] = (this.state.team_2_win_prob)
                        }
                    }
                    this.setState({ odds: odds, odds_loading: false, errorOdds: false }, () => {
                        this.getPlayersAndStats(predictions);
                    });
                }
            }
        })
        .catch(error => {
            console.error("Error fetching match odds:", error);
            this.setState({ odds: [], odds_loading: false, errorOdds: true, apiError: true });
            this.getPlayersAndStats(predictions);
        });
    }

    getPlayersAndStats(predictions) {
        const team1Players = this.getPlayers(predictions, 0);
        const team2Players = this.getPlayers(predictions, 1);

        // Initialize player stats in state as empty objects with loading: true
        const initialTeam1Stats = {};
        team1Players.forEach(player => {
            initialTeam1Stats[player.username] = { loading: true };
        });
        const initialTeam2Stats = {};
        team2Players.forEach(player => {
            initialTeam2Stats[player.username] = { loading: true };
        });
        this.setState({ team_1_stats: initialTeam1Stats, team_2_stats: initialTeam2Stats }, () => {
            team1Players.forEach(player => this.fetchPlayerStats(predictions, 0, player, 'team_1_stats', predictions.teams[0]?.players)); // Pass all players of team 1
            team2Players.forEach(player => this.fetchPlayerStats(predictions, 1, player, 'team_2_stats', predictions.teams[1]?.players)); // Pass all players of team 2
        });

        this.setState({ dataLoaded: true });
    }

    getPlayers(json, side) {
        return (json.teams[side]?.players || []).map((player, index) => ({
            name: player.name,
            username: player.username || player.name,
            id: player.id,
            role: player.role || player.stats?.role, // ADD THIS LINE - Try to get role from stats if direct role is missing
            dob: player.DOB,
            image: player.image
        }));
    }

    match_string(str1, str2) {
        return str1.toLowerCase().includes(str2.toLowerCase()) || str2.toLowerCase().includes(str1.toLowerCase());
    }

    fetchPlayerStats(predictions, team_num, player, state_var, team_players_full) { // Added team_players_full
        let prediction_stats = [];

        if (this.props.params.game_type == 'cricket' || this.props.params.game_type == 'kabaddi') {
            prediction_stats = [{ [player.name]: team_players_full.find((item) => (item.name == player.name)) || {} }]; // Use team_players_full
        } else {
            prediction_stats = [{ [player.username]: team_players_full.find((item) => (item.username == player.username)) || {} }]; // Use team_players_full
        }

        this.fetchMatchupStatsForPlayer(prediction_stats, player, state_var, team_num, [], predictions, team_players_full); // Pass team_players_full
    }

    fetchMatchupStatsForPlayer(prediction_stats, player, state_var, team_num, projected_stats, predictions, team_players_full) { // Pass team_players_full
        // Parameter Sanitization (No changes needed)
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const team_1_encoded = encodeURIComponent(this.props.params.team_1);
        const team_2_encoded = encodeURIComponent(this.props.params.team_2);

        fetch("https://jrkv0kwh80.execute-api.us-east-1.amazonaws.com/prod/?game=" + game_type_encoded + "&team1=" + team_1_encoded + "&team2=" + team_2_encoded, {
            method: "GET",
            headers: {
                "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
            }
        })
        .then(res => res.json())
        .then((json) => {
            const body = JSON.parse(json.body);
            let matchup_stats_body = [];
            if (!("no previous matches found" in body)) {
                matchup_stats_body = body;
            }
            this.fetchPredictionsForPlayer(prediction_stats, matchup_stats_body, player, state_var, team_num, projected_stats, predictions, team_players_full); // Pass team_players_full
        })
        .catch(error => {
            console.error(`Error fetching matchup stats for player ${player.username} Team ${team_num + 1}:`, error);
            this.setState(prevState => {
                let updatedState = { ...prevState[state_var] };
                updatedState[player.username] = { loading: false, error: true }; // Set error for player
                return { [state_var]: updatedState };
            }, () => {
                this.fetchPredictionsForPlayer(prediction_stats, [], player, state_var, team_num, projected_stats, predictions, team_players_full); // Still try to fetch predictions, pass team_players_full
            });
        });
    }

    fetchPredictionsForPlayer(prediction_stats, matchup_stats, player, state_var, team_num, projected_stats, predictions, team_players_full) { // Pass team_players_full
        const players_str = player.username;

        // Parameter Sanitization (No changes needed)
        const players_str_encoded = encodeURIComponent(players_str);
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);

        fetch(
            "https://j3auzrc071.execute-api.us-east-1.amazonaws.com/prod/?players=" + players_str_encoded + "&game=" + game_type_encoded, {
            method: "GET",
            headers: {
                "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
            },
        })
        .then(res => res.json())
        .then((json) => {
            if (!json.body) { // Defensive check for json.body
                console.error("API response body is undefined for player predictions, player:", player.username, "team_num:", team_num);
                this.setState(prevState => {
                    let updatedState = { ...prevState[state_var] };
                    updatedState[player.username] = { loading: false, error: true }; // Set error for player
                    return { [state_var]: updatedState };
                });
                return;
            }
            const body = JSON.parse(json.body);
            let player_predictions;
            if (projected_stats.length != 0) {
                player_predictions = [{
                    [player.username]: this.getGameSpecificStats(prediction_stats[0][player.username], body?.[player.username] || {}, matchup_stats, player, team_num, projected_stats[0], team_players_full, predictions, this.state.odds_complete) // Pass odds here
                }];
            } else {
                player_predictions = [{
                    [player.username]: this.getGameSpecificStats(prediction_stats[0][player.username], body?.[player.username] || {}, matchup_stats, player, team_num, [], team_players_full, predictions, this.state.odds_complete) // Pass odds here
                }];
            }
            // Call the external formatTableFunction and update state
            const formattedData = formatTableFunction(player_predictions[0]?.[player.username], player, this.props.params.game_type, this.state);
            formattedData.player = player; // Attach the player object to formattedData
            formattedData.player.playerDataRaw = prediction_stats[0][player.username];

            this.setState(prevState => {
                const updatedState = { ...prevState[state_var] };
                updatedState[player.username] = {
                    loading: false,
                    data: formattedData,
                    error: false
                };
                return { [state_var]: updatedState };
            }, () => {
                // State updated
            });
        })
        .catch(error => {
            console.error(`Error fetching player predictions for player ${player.username} Team ${team_num + 1}:`, error);
            this.setState(prevState => {
                let updatedState = { ...prevState[state_var] };
                updatedState[player.username] = { loading: false, error: true }; // Set error for player
                return { [state_var]: updatedState };
            });
        });
    }

    remove(array, item) {
        var new_array = [];
        if (array && (array.length != 1 || (array[0] && array[0] != []))) {
            new_array = array.filter((ar) => ar != item);
        }
        return new_array;
    }

    getAverage(array) {
        array = this.remove(array, 'None');
        array = this.remove(array, null);
        if (array && array.length != 0) {
            return (array.reduce((a, b) => a + b) / array.length).toFixed(2);
        }
        else {
            return 'n/a';
        }
    }

    getMedian(array) {
        array = this.remove(array, 'None');
        if (array && array.length != 0 && array[0] != null) {
            const mid = Math.floor(array.length / 2);
            const nums = [...array].sort((a, b) => a - b);
            return (array.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).toFixed(2);;
        } else {
            return 'n/a';
        }
    }

    getMatchupLine(win_stat, loss_stat, team_num) {
        if (win_stat == 'n/a' || loss_stat == 'n/a') {
            return 'n/a'
        }
        var win_prob;
        if (team_num == 0) {
            win_prob = this.state.team_1_win_prob;
        } else {
            win_prob = this.state.team_2_win_prob;
        }
        const win_prob_num = Number(win_prob); // Convert win_prob to number
        const loss_prob_num = 1 - win_prob_num;

        if (isNaN(win_prob_num) || isNaN(loss_prob_num)) { // Check for NaN
            return 'n/a';
        }
        return ((win_stat * win_prob_num) + (loss_stat * loss_prob_num)).toFixed(2)
    }

    calculateProbabilityFromDistribution = (goal, distribution) => {
        if (!distribution || !distribution.distribution || !distribution.bin_size ||
            distribution.start === undefined || distribution.end === undefined) {
            return 0.5;
        }

        if (goal <= distribution.start) return 0;
        if (goal >= distribution.end) return 1.0;

        const bin_size = distribution.bin_size;
        const start = distribution.start;

        const bin_index = Math.floor((goal - start) / bin_size);

        if (!distribution._cachedCumulativeDistribution) {
            // Create the cache once
            const weights = distribution.distribution.map(Number);
            const totalWeight = weights.reduce((a, b) => a + b, 0);

            // Pre-compute cumulative distribution
            const cumulative = [];
            let sum = 0;
            for (let i = 0; i < weights.length; i++) {
                sum += weights[i];
                cumulative.push(sum / totalWeight);
            }

            // Cache it on the distribution object
            distribution._cachedCumulativeDistribution = cumulative;
            distribution._cachedWeights = weights;
            distribution._cachedTotalWeight = totalWeight;
        }

        // Use cached values
        const normalizedCumulative = distribution._cachedCumulativeDistribution;
        const weights = distribution._cachedWeights;

        // Calculate probability with cached values
        let cumulative_probability = bin_index > 0 ? normalizedCumulative[bin_index - 1] : 0;
        const within_bin_offset = (goal - (start + bin_index * bin_size)) / bin_size;
        const bin_probability = weights[bin_index] / distribution._cachedTotalWeight;

        cumulative_probability += within_bin_offset * bin_probability;
        return parseFloat(cumulative_probability.toFixed(3));
    }

    probabilityToAmericanOdds  = (probability) => {
        if (probability < 0 || probability > 1) {
            throw new Error("Probability must be between 0 and 1.");
        }

        if (probability > 0.5) {
            return Math.round(- (probability / (1 - probability)) * 100);
        } else {
            return Math.round(((1 - probability) / probability) * 100);
        }
    }

    getGameSpecificStats(
      prediction_stats,
      win_loss_stats,
      matchup_stats,
      player,
      team_num,
      projected_stats,
      team_players_full,
      predictions,
      odds // odds is already a JSON object in the expected format
    ) {
      // ------------------------ 1. Setup Base Columns and Last-n Count ------------------------
      let stats_columns = this.state.columns;
      let last_n = 5;
      if (
        this.props.params.game_type === "cricket" ||
        this.props.params.game_type === "kabaddi"
      ) {
        last_n = 10;
      } else {
        for (let index in prediction_stats.projections || {}) {
          if (index.includes("_prediction")) {
            stats_columns.add(index.substring(0, index.indexOf("_prediction")));
          } else if (index.includes("_list")) {
            stats_columns.add(index.substring(0, index.indexOf("_list")));
          }
        }
        this.setState({ columns: stats_columns });
      }

      // ------------------------ 2. Base Stats Dictionaries ------------------------
      const prediction_dict = { type: "prediction" };
      const prediction_plus_bin_dict = { type: "prediction_plus_bin" };
      const prediction_minus_bin_dict = { type: "prediction_minus_bin" };
      const last_n_dict = { type: "last " + last_n };
      if (prediction_stats.projections) {
        last_n_dict.participation =
          prediction_stats.projections["participation_last" + last_n];
      }
      const win_dict = { type: "wins" };
      const loss_dict = { type: "losses" };
      const matchup_perf_dict = { type: "matchup performance" };
      const matchup_line_dict = { type: "matchup line" };
      const result_dict = { type: "results" };
      const line_rating_dict = { type: "line rating" };
      let projection_dict_list = []; // Will hold our bookmaker projection rows

      // ------------------------ 3. Process Bookmaker Odds (Player Projections) ------------------------
      if (odds) {
        const externalData = odds; // Already a JSON object
        const teamsData = externalData.teams || [];
        teamsData.forEach(teamData => {
          teamData.players?.forEach(playerInfo => {
            // Match the player by 'username' or 'name'
            if (
              (playerInfo.username && playerInfo.username === player.username) ||
              (playerInfo.name && playerInfo.name === player.username)
            ) {
              if (playerInfo.projections) {
                // Group projections by "bookmaker opening" and "bookmaker closing"
                const projectionGroups = {};
                for (const market in playerInfo.projections) {
                  const marketProjections = playerInfo.projections[market];
                  if (marketProjections) {
                    for (const provider in marketProjections) {
                      // Extract dynamic bookmaker name from provider key (e.g. "hotstreak")
                      const bookmakerName = provider.split("_")[0];
                      const lines = marketProjections[provider];
                      if (Array.isArray(lines) && lines.length > 0) {
                        // Process the opening line (first element)
                        const openingLine = lines[0];
                        const openingKey = `${bookmakerName} opening`;
                        if (!projectionGroups[openingKey]) {
                          projectionGroups[openingKey] = { type: openingKey };
                          // Initialize all expected stat columns to "n/a"
                          for (const col of stats_columns) {
                            if (col !== "type" && col !== "participation") {
                              projectionGroups[openingKey][col] = "n/a";
                            }
                          }
                        }
                        projectionGroups[openingKey][market] = openingLine.line;

                        // Process the closing line (last element) only if there is more than one
                        if (lines.length > 1) {
                          const closingLine = lines[lines.length - 1];
                          const closingKey = `${bookmakerName} closing`;
                          if (!projectionGroups[closingKey]) {
                            projectionGroups[closingKey] = { type: closingKey };
                            for (const col of stats_columns) {
                              if (col !== "type" && col !== "participation") {
                                projectionGroups[closingKey][col] = "n/a";
                              }
                            }
                          }
                          projectionGroups[closingKey][market] = closingLine.line;
                        }
                      }
                    }
                  }
                }
                projection_dict_list.push(...Object.values(projectionGroups));
              }
            }
          });
        });
      } else {
        console.log("No or invalid odds data available for bookmaker projections.");
      }

      // ------------------------ 4. Process Base Player Stats (Predictions, Last-n, etc.) ------------------------
      for (const column_name of stats_columns) {
        if (column_name !== "type" && column_name !== "participation") {
          const prediction_var = column_name + "_prediction";
          let predictionValue;
          try {
              predictionValue = this.getMedian([
                  prediction_stats.projections?.[prediction_var]
              ]);
          } catch (error) {
              predictionValue = "n/a";
          }

          // --- START: Prediction Line with Probability ---
          const distribution_var = column_name + "_distribution";
          const distribution = prediction_stats.projections?.[distribution_var];


          if (distribution && predictionValue !== "n/a") {
              const pred = parseInt(predictionValue) + 0.5;
              const cumulative_prob = this.calculateProbabilityFromDistribution(pred, distribution);
              const over_prob = (1 - cumulative_prob).toFixed(2);
              const under_prob = cumulative_prob.toFixed(2);
              prediction_dict[column_name] = `${pred.toFixed(1)} (O: ${over_prob}, U: ${under_prob})`; // Format with probabilities
          } else {
              prediction_dict[column_name] = predictionValue !== "n/a" ? `${parseInt(predictionValue) + 0.5} (No Prob)` : "n/a"; // If no distribution, show "No Prob"
          }

          if (distribution && predictionValue !== "n/a") {
              const bin_size = distribution.bin_size;

              const pred = parseInt(predictionValue) + 0.5;

              const plusBinValue = pred + bin_size;
              const cumulativePlusProb = this.calculateProbabilityFromDistribution(plusBinValue, distribution);
              const plusOverProb = (1 - cumulativePlusProb).toFixed(2);
              const plusUnderProb = cumulativePlusProb.toFixed(2);
              prediction_plus_bin_dict[column_name] = `${plusBinValue.toFixed(1)} (O: ${plusOverProb}, U: ${plusUnderProb})`;

              const minusBinValue = pred - bin_size;
              const cumulativeMinusProb = this.calculateProbabilityFromDistribution(minusBinValue, distribution);
              const minusOverProb = (1 - cumulativeMinusProb).toFixed(2);
              const minusUnderProb = cumulativeMinusProb.toFixed(2);
              prediction_minus_bin_dict[column_name] = `${minusBinValue.toFixed(1)} (O: ${minusOverProb}, U: ${minusUnderProb})`;
          } else {
              prediction_plus_bin_dict[column_name] = "n/a";
              prediction_minus_bin_dict[column_name] = "n/a";
          }

          const last_n_var = column_name + "_list";
          try {
              last_n_dict[column_name] = this.getAverage(
                  prediction_stats[last_n_var]?.slice(-last_n)
              );
          } catch (error) {
              last_n_dict[column_name] = "n/a";
          }
          const line_rating_var = column_name + "_line_rating";
          try {
              line_rating_dict[column_name] = this.getMedian([
                  prediction_stats.projections?.[line_rating_var]
              ]);
          } catch (error) {
              line_rating_dict[column_name] = "n/a";
          }

          const win_column_name = "wins_" + column_name + "_list";
          const loss_column_name = "loss_" + column_name + "_list";

          if (this.props.params.timeframe === "upcoming-matches") {
            try {
              win_dict[column_name] = this.getMedian(
                prediction_stats[win_column_name]?.slice(-8)
              );
            } catch (error) {
              win_dict[column_name] = "n/a";
            }
            try {
              loss_dict[column_name] = this.getMedian(
                prediction_stats[loss_column_name]?.slice(-8)
              );
            } catch (error) {
              loss_dict[column_name] = "n/a";
            }
            try {
              const matchupData = matchup_stats?.[player.username] || [];
              if (matchupData.length > 0 && matchupData[0][column_name]) {
                matchup_perf_dict[column_name] = this.getAverage(
                  matchupData.map(game => game[column_name])
                );
              } else if (matchupData.length > 0) {
                matchup_perf_dict[column_name] = this.getAverage(
                  matchup_stats[player.username].map(
                    game => game[column_name.toUpperCase()]
                  )
                );
              } else {
                matchup_perf_dict[column_name] = "n/a";
              }
            } catch (error) {
              matchup_perf_dict[column_name] = "n/a";
            }
            try {
              matchup_line_dict[column_name] = this.getMatchupLine(
                win_dict[column_name],
                loss_dict[column_name],
                team_num
              );
            } catch (error) {
              matchup_line_dict[column_name] = "n/a";
            }
          } else {
            if (prediction_stats.results && Array.isArray(prediction_stats.results)) {
                const resultValues = prediction_stats.results.map(result => result[column_name] || 'n/a'); // Get array of values
                result_dict[column_name] = resultValues.join(', '); // Join with comma and space
            } else {
                result_dict[column_name] = 'n/a'; // Or handle no results case as needed
            }
          }
        }
      }

      // ------------------------ 5. Combine All Rows and Return ------------------------
      const allRows = [
        prediction_dict,
        prediction_plus_bin_dict,
        prediction_minus_bin_dict,
        line_rating_dict,
        last_n_dict,
        win_dict,
        loss_dict,
        matchup_perf_dict,
        matchup_line_dict,
        result_dict,
        ...projection_dict_list
      ];
      return allRows;
    }


    componentDidMount() {
        // Parameter Sanitization (No changes needed)
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const timeframe_encoded = encodeURIComponent(this.props.params.timeframe);
        const matchid_encoded = encodeURIComponent(this.props.params.matchid);

        this.setState({ matchup_loading: true, errorMatchup: false }, () => { // Set loading state for matchup
            fetch(
                "https://rimbleanalytics.com/predictions/" + game_type_encoded + "/" + timeframe_encoded + "/?matchid=" + matchid_encoded,
                {
                    method: "GET",
                    headers: {
                        "x-api-key": this.getAPIKey(this.props.params.game_type),
                    }
                })
                .then(res => res.json())
                .then((json) => {
                    this.processAPIResponse(json);
                })
                .catch(error => {
                    console.error("Error fetching initial game data:", error);
                    this.setState({ matchup_loading: false, errorMatchup: true, errorMessage: "Error fetching initial game data." }); // Error for Matchup
                });
        });
    }

    processAPIResponse = (json) => {
        if (!json || !Array.isArray(json) || json.length === 0) {
            this.setState({ matchup_loading: false, errorMatchup: true, errorMessage: "Invalid initial game data response." });
            return;
        }
        this.getLines(json[0]);
        const updatedMatchup = json.map(match => {
            const team1WinResult = match.teams && match.teams[0] ? match.teams[0].win_result : undefined;
            const team2WinResult = match.teams && match.teams[1] ? match.teams[1].win_result : undefined;

            return {
                ...match,
                team_1_win_result: team1WinResult,
                team_2_win_result: team2WinResult,
                time: match.time
            };
        });
        this.setState({
            matchup: updatedMatchup,
            game_type: this.props.params.game_type,
            timeframe: this.props.params.timeframe,
            matchid: this.props.params.matchid,
            league: json[0].league,
            team_1_name: this.props.params.team_1,
            team_2_name: this.props.params.team_2,
            matchup_loading: false, errorMatchup: false // **Set matchup_loading to false here**
        }, () => {
            if (this.props.params.game_type == "cricket") {
                this.setState({ columns: new Set(["type", "runs", "strikerate", "wickets", "economy", "participation"]) });
            }
            if (this.props.params.game_type == "kabaddi") {
                this.setState({ columns: new Set(["type", "points_total", "participation"]) });
            }
        });
    }

    // goBack, convertTime, render functions (No changes needed)
    goBack() {
        this.props.history.back();
    }


    convertTime = (time) => {
        let date = new Date(`1970-01-01T${time}Z`);
        date.setHours(date.getHours() + (this.props.isUTC ? -5 : 5));
        return date.toISOString().split("T")[1].split(".")[0];
    };


    downloadFullGameCSV = () => {
        if (this.state.matchup_loading || this.state.odds_loading || Object.values(this.state.team_1_stats).some(player => player.loading) || Object.values(this.state.team_2_stats).some(player => player.loading)) {
            setTimeout(this.downloadFullGameCSV, 500);
            return;
        }
        let fullCSVData = "";

        const playerCSVColumns = [
            { name: 'Player Username' },
            { name: 'Player Role' },
            { name: 'Player Name' },
            { name: 'Team' },
            { name: 'Row Type' },
            { name: 'Market' },
            { name: 'Target Line' },
            { name: 'Over Unmarginated American Odds' },
            { name: 'Under Unmarginated American Odds' },
            { name: 'Injury Status' }
        ];

        function processTeamStats(teamStats, teamName) {
            const playerDataRows = [];

            for (const playerUsername in teamStats) {
                const playerStat = teamStats[playerUsername].data;
                if (!playerStat) continue;

                const player = playerStat.player;
                playerStat.gridData.forEach(rowData => {
                    if (!rowData.type.startsWith('prediction')) return;

                    for (const market in rowData) {
                        if (market === 'type') continue;

                        const statName = market;
                        const projectionData = player.playerDataRaw;
                        let overProb = 'N/A';
                        let underProb = 'N/A';
                        const targetLineValue = rowData[market];

                        if (projectionData && projectionData.projections) {
                            let distribution = projectionData.projections[`${statName}_distribution`];
                            if (distribution === undefined) {
                                distribution = projectionData.projections[`${statName.toUpperCase()}_distribution`];
                            }
                            if (distribution === undefined) {
                                distribution = projectionData.projections[`${statName.toLowerCase()}_distribution`];
                            }

                            if (distribution !== undefined) {
                                const pred = parseFloat(targetLineValue);
                                const cumulative_prob = this.calculateProbabilityFromDistribution(pred, distribution);
                                overProb = (1 - cumulative_prob).toFixed(3);
                                underProb = cumulative_prob.toFixed(3);
                            }
                        }

                        let extractedTargetLine = 'N/A';
                        if (typeof targetLineValue === 'string') {
                            extractedTargetLine = targetLineValue ? targetLineValue.split(' ')[0] : 'N/A';
                        } else {
                            extractedTargetLine = targetLineValue;
                        }

                        if (extractedTargetLine > 0 && extractedTargetLine !== 'N/A') {
                            playerDataRows.push({
                                'Player Username': player.username || 'N/A',
                                'Player Role': player.role || 'N/A',
                                'Player Name': player.name || 'N/A',
                                'Team': teamName,
                                'Row Type': rowData.type,
                                'Market': market,
                                'Target Line': extractedTargetLine,
                                'Over Unmarginated American Odds': this.probabilityToAmericanOdds(overProb),
                                'Under Unmarginated American Odds': this.probabilityToAmericanOdds(underProb),
                                'Injury Status': 'None'
                            });
                        }
                    }
                });
            }

            return playerDataRows;
        }

        // Process both teams
        const team1PlayerDataRows = processTeamStats.call(this, this.state.team_1_stats, this.state.team_1_name);
        fullCSVData += convertToCSV(team1PlayerDataRows, playerCSVColumns);

        const team2PlayerDataRows = processTeamStats.call(this, this.state.team_2_stats, this.state.team_2_name);
        fullCSVData += convertToCSV(team2PlayerDataRows, playerCSVColumns, false);

        // Odds Table (No changes needed)
        const oddsColumns = [
            'team_1_name',
            'team_2_name',
            'team_1_rimble_win_prob',
            'team_2_rimble_win_prob',
            'team_1_bookmaker_win_prob',
            'team_2_bookmaker_prob',
            'bookmaker'
        ];
        const oddsDataForCSV = this.state.odds.map(odd => ({
            'team_1_name': odd.team_1_name,
            'team_2_name': odd.team_2_name,
            'team_1_rimble_win_prob': odd.team_1_rimble_win_prob,
            'team_2_rimble_win_prob': odd.team_2_win_prob,
            'team_1_bookmaker_win_prob': odd.team_1_bookmaker_win_prob,
            'team_2_bookmaker_prob': odd.team_2_bookmaker_prob,
            'bookmaker': odd.bookmaker
        }));
        fullCSVData += convertToCSV(oddsDataForCSV, oddsColumns);


        const blob = new Blob([fullCSVData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `${this.state.game_type}_${this.state.matchid}_player_odds_data.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };


    render() {
        const hasMatchupData = this.state.matchup && this.state.matchup.length > 0;
        const hasOddsData = this.state.odds && this.state.odds.length > 0;


        return (

            <Layout>
                <div className="main-body">
                    {/* -------------------- Top Section - Improved UI -------------------- */}
                    <div className="dashboard-top-section">
                        <button className="back-button" onClick={this.handleGoBack}>
                            Back
                        </button>
                        <button className="download-full-csv-button" onClick={this.downloadFullGameCSV}>
                            Download Player & Odds CSV
                        </button>
                        {this.state.matchup_loading || this.state.odds_loading || Object.values(this.state.team_1_stats).some(player => player.loading) || Object.values(this.state.team_2_stats).some(player => player.loading) ? <p className="loading-text">Data is loading, please wait...</p> : null}
                        {this.state.apiError ? <p className="error-text">Error loading some data. Please refresh or try again later.</p> : null}
                        {!(this.state.matchup_loading || this.state.odds_loading || Object.values(this.state.team_1_stats).some(player => player.loading) || Object.values(this.state.team_2_stats).some(player => player.loading)) && !this.state.apiError ? <p className="refresh-text">If data is missing, please try refreshing the page...</p> : null}


                        <div className="game-info">
                            <p>Game: <strong>{this.state.game_type}</strong></p>
                            <p>Timeframe: <strong>{this.state.timeframe}</strong></p>
                        </div>
                    </div>
                    {/* -------------------- End Top Section -------------------- */}


                    <h3 className="section-title">Matchup Details</h3>
                    {hasMatchupData && !this.state.matchup_loading && !this.state.errorMatchup ? (
                      <Grid
                        data={this.state.matchup.map(match => ({
                          ...match,
                          time: this.convertTime(match.time),
                        }))}
                        columns={[
                          { name: 'matchid' },
                          { name: 'date' },
                          { name: 'time' },
                          { name: 'league' },
                          { name: 'team_1_name' },
                          { name: 'team_2_name' },
                          { name: 'team_1_win_result' },
                          { name: 'team_2_win_result' },
                          { name: 'source_url' },
                        ]}
                        style={{
                          table: {
                            width: '100%',
                          },
                        }}
                      />
                    ) : (
                        !this.state.matchup_loading && !this.state.errorMatchup && <div>No matchup details available.</div>
                    )}
                    {this.state.matchup_loading && <div>Loading Matchup Details...</div>}
                    {this.state.errorMatchup && <div>Error loading Matchup Details.</div>}


                    <h3 className="section-title">Team 1 Player Stats</h3>

                    {Object.entries(this.state.team_1_stats).map(([username, playerStats]) => {
                        if (playerStats.data) {
                            const playerData = playerStats.data; // playerData now holds the formatted player data
                            const player = playerData.player; // Access the original player object from formatted data
                            return (
                                <div key={username} className="player-stats-container">
                                    {/* Player Information Rows - No Header anymore */}
                                    {player.username && <p className="player-info">Username - {player.username}</p>}
                                    {player.role && <p className="player-info">Role - {player.role}</p>}
                                    {player.name && <p className="player-info">Name - {player.name}</p>}
                                    {player.dob && <p className="player-info">DOB - {player.dob}</p>} {/* Assuming DOB is available in player object */}
                                    {player.image && (
                                        <p className="player-image">
                                            Image - <a href={player.image} target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    )}
                                    <Grid
                                        data={playerData.gridData}
                                        columns={this.getPlayerColumns(playerData)}
                                    />
                                </div>
                            );
                        } else if (playerStats.loading) {
                            return (
                                <div key={username} className="player-stats-container">
                                    <p className="player-name-header">{username} Stats</p> {/* Keep loading message if needed */}
                                    <div>Loading Player Stats...</div>
                                </div>
                            );
                        } else if (playerStats.error) {
                            return (
                                <div key={username} className="player-stats-container">
                                    <p className="player-name-header">{username} Stats</p> {/* Keep error message if needed */}
                                    <div>Error loading player stats.</div>
                                </div>
                            );
                        }
                        return null; // Or a default "No Data" state if needed
                    })}


                    <h3 className="section-title">Team 2 Player Stats</h3>

                    {Object.entries(this.state.team_2_stats).map(([username, playerStats]) => {
                        if (playerStats.data) {
                            const playerData = playerStats.data; // playerData now holds the formatted player data
                            const player = playerData.player; // Access the original player object from formatted data

                            return (
                                <div key={username} className="player-stats-container">
                                    {/* Player Information Rows - No Header anymore */}
                                    {player.username && <p className="player-info">Username - {player.username}</p>}
                                    {player.role && <p className="player-info">Role - {player.role}</p>}
                                    {player.name && <p className="player-info">Name - {player.name}</p>}
                                    {player.dob && <p className="player-info">DOB - {player.dob}</p>} {/* Assuming DOB is available in player object */}
                                    {player.image && (
                                        <p className="player-image">
                                            Image - <a href={player.image} target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    )}
                                    <Grid
                                        data={playerData.gridData}
                                        columns={this.getPlayerColumns(playerData)}
                                    />
                                </div>
                            );
                        } else if (playerStats.loading) {
                            return (
                                <div key={username} className="player-stats-container">
                                    <p className="player-name-header">{username} Stats</p> {/* Keep loading message if needed */}
                                    <div>Loading Player Stats...</div>
                                </div>
                            );
                        } else if (playerStats.error) {
                            return (
                                <div key={username} className="player-stats-container">
                                    <p className="player-name-header">{username} Stats</p> {/* Keep error message if needed */}
                                    <div>Error loading player stats.</div>
                                </div>
                            );
                        }
                        return null; // Or a default "No Data" state if needed
                    })}


                    <h3 className="section-title">Odds Table</h3>
                    {hasOddsData && !this.state.odds_loading && !this.state.apiError ? ( // Only render if data, not loading and no error
                        <Grid
                            data={this.state.odds}
                            columns={[
                                'team_1_name',
                                'team_2_name',
                                'team_1_rimble_win_prob',
                                'team_2_rimble_win_prob',
                                'team_1_bookmaker_win_prob',
                                'team_2_bookmaker_prob',
                                'bookmaker'
                            ]}
                        />
                    ) : (
                        !this.state.odds_loading && !this.state.apiError && <div>No odds data available.</div>
                    )}
                     {this.state.odds_loading && <div>Loading Odds Table...</div>}
                     {this.state.apiError && <div>Error loading Odds Table.</div>}

                </div>
            </Layout>
        );
    }

    getPlayerColumns(playerStat) {
        return playerStat?.gridColumns || [];
    }
}


const mapDispatchToProps = (dispatch) => ({
    navigateAction: (path) => dispatch(navigateAction(path)),
});

const ConnectedTeams = connect(null, mapDispatchToProps)(Teams);


export default withNavigation((props) => {
    const params = useParams();
    return <ConnectedTeams {...props} params={params} />;
});
